import _ from 'lodash'
import { transformObjToUrlQuery } from 'utils/common'
import fetchApi from './config'

export const getList = async (filter) => {
  return await fetchApi(`/areas${transformObjToUrlQuery(filter)}`)
}

export const getAll = async (name = '') => {
  let query = `?${name}`
  return await fetchApi(`/areas${query}`)
}

export const getArea = async (id) => {
  return await fetchApi(`/areas/${id}`)
}

export const createArea = async (data) => {
  return await fetchApi(`/areas`, data, 'post')
}

export const updateArea = async (id, data) => {
  return await fetchApi(`/areas/${id}`, data, 'put')
}

export const deleteArea = async (id) => {
  return await fetchApi(`/areas/${id}`, {}, 'delete')
}

/**
 * Add stores to the area
 * @param {string} id of area
 * @param {*} ids of stores
 * @returns
 */
export const addStore = async (id, ids) => {
  return await fetchApi(
    `/areas/${id}/add-store`,
    {
      stores_id: ids,
    },
    'post'
  )
}

/**
 * Remove stores from the area
 * @param {string} id of area
 * @param {*} ids of stores
 * @returns
 */
export const removeStore = async (id, ids) => {
  return await fetchApi(
    `/areas/${id}/remove-store`,
    {
      stores_id: ids,
    },
    'delete'
  )
}
