import { isArray } from 'lodash'
import { useSelector } from 'react-redux'
import { userSlector } from 'redux/slices/userSlice'

const useAuthorize = (roles) => {
  const user = useSelector(userSlector)

  if (!user?.isLoggedIn) return false

  let isAllow = false

  if (isArray(roles) && roles.length > 0) {
    roles.forEach((role) => {
      if (role === user.user?.role?.level) {
        isAllow = true
      }
    })
  }

  return isAllow
}

export default useAuthorize
