import CloseIcon from '@mui/icons-material/Close'
import { Alert, CssBaseline, IconButton, Snackbar, ThemeProvider } from '@mui/material'
import ProtectedAuth from 'components/auth/ProtectedAuth'
import ProtectedAuthorize from 'components/auth/ProtectedAuthorize'
import Loadable from 'components/Loadable'
import { MESSAGE_TYPES } from 'constants/common'
import UserRoleLevel from 'constants/user-role-level'
import useAppInit from 'hooks/useAppInit'
import TicketHandleByMe from 'pages/ticket/TicketListing/TicketHandleByMe'
import TicketManagementByMe from 'pages/ticket/TicketListing/TicketManagementByMe'
import TicketMe from 'pages/ticket/TicketListing/TicketMe'
import TicketNeedApproval from 'pages/ticket/TicketListing/TicketNeedApproval'
import { lazy, useState } from 'react'
import { Provider, shallowEqual, useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { layoutActions } from 'redux/slices/layoutSlice'
import { BASE_THEME } from './constants/theme'
import { initTranslations } from './languages'
import { persistor, store } from './redux/store'

const PageNotFound = Loadable(lazy(() => import('pages/404')))
const Login = Loadable(lazy(() => import('pages/auth/Login')))
const MainLayout = Loadable(lazy(() => import('layout/MainLayout')))
const TicketDail = Loadable(lazy(() => import('pages/ticket/TicketDetail/Main')))
const TicketListing = Loadable(lazy(() => import('pages/ticket/TicketListing')))
const UserListing = Loadable(lazy(() => import('pages/user/UserListing')))
const CategoryListing = Loadable(lazy(() => import('pages/category/CategoryListing')))
const AreaListing = Loadable(lazy(() => import('pages/area/AreaListing')))
const TagListing = Loadable(lazy(() => import('pages/tag/TagListing')))
const StoreListing = Loadable(lazy(() => import('pages/store/StoreListing')))
const UserProfile = Loadable(lazy(() => import('pages/user/UserProfile')))
const Report = Loadable(lazy(() => import('pages/Report')))

initTranslations()

const AppRoutes = () => {
  const dispatch = useDispatch()

  const { messages } = useSelector((state) => state.layout, shallowEqual)
  const [isLoading, setIsLoading] = useState(true)

  useAppInit(setIsLoading)

  return (
    !isLoading && (
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/404" element={<PageNotFound />} />
            <Route path="/login" element={<Login className="login" />} />

            <Route element={<ProtectedAuth />}>
              <Route path="/" element={<MainLayout />}>
                {/* <Route path="tickets" element={<TicketListing />} /> */}
                <Route path="tickets" element={<TicketListing />}>
                  <Route path="cua-toi" element={<TicketMe />} />
                  <Route path="toi-xu-ly" element={<TicketHandleByMe />} />
                  <Route path="toi-quan-ly" element={<TicketManagementByMe />} />
                  <Route path="can-phe-duyet" element={<TicketNeedApproval />} />
                </Route>
                <Route path="tickets/:id" element={<TicketDail />} />
                <Route element={<ProtectedAuthorize allowedRoles={[UserRoleLevel.SUPERADMIN]} />}>
                  <Route path="users" element={<UserListing />} />
                </Route>
                <Route path="profile" element={<UserProfile />} />
                <Route
                  element={<ProtectedAuthorize allowedRoles={[UserRoleLevel.SUPERADMIN, UserRoleLevel.COORDINATOR]} />}
                >
                  <Route path="categories" element={<CategoryListing />} />
                </Route>
                <Route
                  element={<ProtectedAuthorize allowedRoles={[UserRoleLevel.SUPERADMIN, UserRoleLevel.AREA_MANAGER]} />}
                >
                  <Route path="areas" element={<AreaListing />} />
                </Route>
                <Route element={<ProtectedAuthorize allowedRoles={[UserRoleLevel.SUPERADMIN]} />}>
                  <Route path="stores" element={<StoreListing />} />
                </Route>
                <Route
                  element={<ProtectedAuthorize allowedRoles={[UserRoleLevel.SUPERADMIN, UserRoleLevel.COORDINATOR]} />}
                >
                  <Route path="reports" element={<Report />} />
                </Route>
                <Route path="tags" element={<TagListing />} />
                {/* <Route path="settings" element={<Setting />} /> */}
              </Route>
            </Route>

            <Route path="*" element={<Navigate to="/404" replace />} />
          </Routes>
        </BrowserRouter>
        <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} style={{ zIndex: '99999999' }}>
          <div sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
            {Object.entries(messages)
              .sort((a, b) => a[1].createdAt > b[1].createdAt)
              .map(([k, msg], index) => (
                <Alert
                  icon={
                    msg.type === MESSAGE_TYPES.SUCCESS ? (
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M7.003.334C3.329.334.336 3.328.336 7.001c0 3.673 2.993 6.667 6.667 6.667 3.673 0 6.666-2.993 6.666-6.667 0-3.673-2.993-6.667-6.666-6.667Zm3.186 5.134-3.78 3.78a.5.5 0 0 1-.706 0L3.816 7.36a.503.503 0 0 1 0-.707.503.503 0 0 1 .707 0l1.533 1.534L9.483 4.76a.503.503 0 0 1 .706 0 .503.503 0 0 1 0 .707Z"
                          fill="#00B578"
                        />
                      </svg>
                    ) : msg.type === MESSAGE_TYPES.ERROR ? (
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M7.003.168C3.329.168.336 3.162.336 6.835c0 3.673 2.993 6.667 6.667 6.667 3.673 0 6.666-2.994 6.666-6.667 0-3.673-2.993-6.667-6.666-6.667Zm2.24 8.2a.503.503 0 0 1 0 .707c-.1.1-.227.147-.354.147a.494.494 0 0 1-.353-.147L7.003 7.542 5.469 9.075a.495.495 0 0 1-.707 0 .503.503 0 0 1 0-.707l1.534-1.533-1.533-1.533a.503.503 0 0 1 0-.707.503.503 0 0 1 .706 0l1.534 1.533 1.533-1.533a.503.503 0 0 1 .707 0 .503.503 0 0 1 0 .707L7.709 6.835l1.534 1.533Z"
                          fill="#FF3141"
                        />
                      </svg>
                    ) : null
                  }
                  key={`notification-message-${index}`}
                  sx={{
                    width: '100%',
                    mb: 1,
                    alignItems: 'center',
                    border: '1px solid',
                    color: '#333333',
                    px: 2,
                    py: 1.5,
                    ...(msg.type === MESSAGE_TYPES.SUCCESS && { background: '#ECFDF5', borderColor: '#A7F3D0' }),
                    ...(msg.type === MESSAGE_TYPES.ERROR && { background: '#FFF1F2', borderColor: '#FECDD3' }),
                  }}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        dispatch(layoutActions.hideMessage(k))
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {msg.content}
                </Alert>
              ))}
          </div>
        </Snackbar>
      </>
    )
  )
}

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={BASE_THEME}>
          <CssBaseline />
          <AppRoutes />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
