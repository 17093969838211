import { Box, DialogActions, DialogContent, FormControl, Grid, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { FieldDatePicker } from 'components/form/DatePicker'
import { FieldSelector } from 'components/form/Selector'
import { Button as ButtonCus } from 'components/ui/Button'
import { Label } from 'components/ui/Label'
import { Modal, useModal } from 'components/ui/Modal'
import { Text } from 'components/ui/Text'
import { Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as areaService from 'services/area.service'
import * as categoryService from 'services/category.service'
import * as storeService from 'services/store.service'
import * as userService from 'services/user.service'
import { formatDateEn } from 'utils/formater'
import ListingTabType from '../constants/tab-type'
import { FieldUserSelector } from 'components/UserSelector'
import { FieldCategorySelector } from 'components/CategorySelector'
import { useLayoutEffect } from 'react'

/**
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {number} props.tab
 * @param {object} props.filter
 * @param {function} props.onChange
 * @returns
 */
export const ModalFilterAdvanced = (props) => {
  const { tab, onChange } = props

  const theme = useTheme()
  let filter = props.filter || {}

  const refForm = useRef()
  const [refModal, { toggle: toggleModal }] = useModal()
  const [stores, setStores] = useState([])
  const [categories, setCategories] = useState([])
  const [areas, setAreas] = useState([])

  useEffect(() => {
    onFetchData()
  }, [])

  const onFetchData = async () => {
    const s = await storeService.getList({ paginate: 0 })
    setStores(s)

    const c = await categoryService.getList({ paginate: 0 })
    setCategories(c)

    const a = await areaService.getList({ paginate: 0 })
    setAreas(a)
  }

  const onFetchTags = async (params, categoryId) => {
    if (!categoryId) return { rows: [], total: 0 }
    const resp = await categoryService
      .getCategoryTags(categoryId, {
        search: params.search,
        page: params.page,
        per_page: params.pageSize,
      })
      .catch((e) => ({ data: [], pagination: { total: 0 } }))
    return { rows: resp.data, total: resp.pagination.total }
  }

  const onFetchUser = async (params) => {
    const resp = await userService
      .getUsers({
        search: params.search,
        page: params.page,
        per_page: params.pageSize,
      })
      .catch((e) => ({ data: [], pagination: { total: 0 } }))
    return { rows: resp.data, total: resp.pagination.total }
  }

  const handleResetForm = (props) => {
    Object.keys(props.initialValues).map((key) => (props.initialValues[key] = undefined))
    props?.resetForm?.()
  }

  const isFilter = () => {
    return [
      // { key: 'status', icon: 'Trạng thái', removeKey: 'status', label: (e) => TicketStatusType[e]['text'] },
      { key: 'created_by', icon: 'Nguời tạo:', removeKey: 'created_by', label: (e) => e?.name },
      { key: 'assignee', icon: 'Nguời xử lý:', removeKey: 'assignee', label: (e) => e?.name },
      { key: 'approve_by', icon: 'Nguời phê duyệt:', removeKey: 'approve_by', label: (e) => e?.name },
      { key: 'area', icon: 'Vùng:', removeKey: 'area', label: (e) => e?.name },
      { key: 'category', icon: 'Danh mục:', removeKey: 'category', label: (e) => e?.name },
      { key: 'tag', icon: 'Nhãn:', removeKey: 'tag', label: (e) => e?.name },
      { key: 'store', icon: 'Cửa hàng:', removeKey: 'store', label: (e) => e?.name },
      { key: ['created_at[gte]', 'created_at[lte]'], icon: 'Thời gian tạo:', removeKey: 'created_at' },
      { key: ['deadline[gte]', 'deadline[lte]'], icon: 'Thời gian xử lý:', removeKey: 'deadline' },
    ]
      .map((e) => ({ ...e, key: Array.isArray(e.key) ? e.key : [e.key] }))
      .map((e, index) => {
        const values = e.key.map((e) => filter[e])
        return values.filter((e) => !!e).length == 0 ? undefined : true
      })
      .filter((e) => !!e).length
  }

  return (
    <>
      <ButtonCus sx={{ ml: 1 }} style={{ height: '36px', padding: '10px 14px' }} onClick={() => toggleModal(true)}>
        {isFilter() > 0 ? (
          <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.577 4.752V3.179c0-.786-.638-1.43-1.41-1.43H3.831c-.772 0-1.41.644-1.41 1.501V4.9c0 .425.284 1.07.638 1.431l2.685 2.862c.283.354.567.999.567 1.502v3.79c0 1.147 1.268 1.791 2.188 1.218l.992-.645c.05-.035.1-.07.149-.113.141-.128.078-.354-.085-.46a4.244 4.244 0 0 1-1.389-1.425 4.362 4.362 0 0 1-.637-2.28 4.429 4.429 0 0 1 4.427-4.427c.34 0 .673.035.985.113.141.035.283.07.425.12a.38.38 0 0 0 .375-.07l.127-.114c.355-.361.709-1.077.709-1.65Zm-2.62 2.663c.254 0 .502.028.736.085a3.357 3.357 0 0 1 2.628 3.28c0 .63-.17 1.225-.482 1.735-.58.97-1.65 1.63-2.883 1.63-.666 0-1.29-.2-1.813-.546-.44-.27-.8-.645-1.063-1.084a3.32 3.32 0 0 1-.489-1.735 3.368 3.368 0 0 1 3.365-3.365Zm-.121 4.583 1.799-1.657a.535.535 0 0 0 .028-.751.535.535 0 0 0-.75-.029l-1.424 1.31-.468-.467a.535.535 0 0 0-.75 0 .535.535 0 0 0 0 .751l.828.829c.106.106.24.156.375.156a.54.54 0 0 0 .362-.142Z"
              fill="#2560E5"
            />
          </svg>
        ) : (
          <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.742 15.614c-.34 0-.68-.085-.992-.255a1.926 1.926 0 0 1-1.006-1.7v-3.79c0-.36-.234-.892-.453-1.168L1.599 5.874C1.153 5.428.806 4.65.806 4.075V2.432c0-1.14.864-2.033 1.962-2.033h9.449c1.084 0 1.962.878 1.962 1.962v1.573c0 .743-.446 1.6-.871 2.018a.535.535 0 0 1-.751 0 .534.534 0 0 1 0-.75c.262-.263.56-.85.56-1.268V2.36a.9.9 0 0 0-.9-.9h-9.45c-.502 0-.899.425-.899.97v1.644c0 .262.213.78.489 1.056l2.727 2.868c.361.447.715 1.197.715 1.87v3.79c0 .467.32.694.46.772.305.17.667.163.95-.007l.992-.638c.205-.12.403-.51.403-.772 0-.29.241-.53.532-.53.29 0 .53.24.53.53 0 .638-.396 1.367-.899 1.672l-.984.637c-.319.192-.68.29-1.042.29Zm3.64-3.882a2.8 2.8 0 0 1-2.797-2.798 2.8 2.8 0 0 1 2.798-2.798 2.8 2.8 0 0 1 2.315 4.368.53.53 0 0 1 .043.038l.709.709a.535.535 0 0 1 0 .75c-.1.1-.241.156-.376.156a.525.525 0 0 1-.375-.155l-.709-.709a.519.519 0 0 1-.038-.043 2.783 2.783 0 0 1-1.57.482Zm0-4.533c-.956 0-1.735.779-1.735 1.735 0 .956.78 1.736 1.736 1.736s1.735-.78 1.735-1.736-.78-1.735-1.735-1.735Z"
              fill="#525252"
            />
          </svg>
        )}
        {
          <Text
            sx={{
              [theme.breakpoints.down('lg')]: {
                display: 'none',
              },
            }}
            noWrap
            ml={1}
            color={isFilter() > 0 ? 'primary.main2' : ''}
          >
            {'Bộ lọc'}
          </Text>
        }
      </ButtonCus>
      <Modal
        ref={refModal}
        title={
          <Text textAlign={'center'} fontWeight={'bold'} fontSize={16}>
            {'Bộ lọc'}
          </Text>
        }
        maxWidth="md"
        includeDialogContent={false}
      >
        <Formik
          enableReinitialize
          initialValues={{
            ...filter,
            created_at_gte: filter['created_at[gte]'] && new Date(filter['created_at[gte]']),
            created_at_lte: filter['created_at[lte]'] && new Date(filter['created_at[lte]']),
            deadline_gte: filter['deadline[gte]'] && new Date(filter['deadline[gte]']),
            deadline_lte: filter['deadline[lte]'] && new Date(filter['deadline[lte]']),
          }}
          onSubmit={({ created_at_gte, created_at_lte, deadline_gte, deadline_lte, ...values }) => {
            onChange({
              ...values,
              'created_at[gte]': created_at_gte && formatDateEn(created_at_gte),
              'created_at[lte]': created_at_lte && formatDateEn(created_at_lte),
              'deadline[gte]': deadline_gte && formatDateEn(deadline_gte),
              'deadline[lte]': deadline_lte && formatDateEn(deadline_lte),
            })
            toggleModal(false)
          }}
        >
          {(props) => {
            refForm.current = props

            return (
              <>
                <DialogContent dividers>
                  <Grid container spacing={1} rowSpacing={2} sx={{ '>.MuiGrid-item': { pt: 2 } }}>
                    {tab !== ListingTabType.HANDLE_BY_ME && (
                      <Grid item sm={6} xs={12}>
                        <FieldUserSelector
                          name="assignee"
                          labelOutline
                          label="Người xử lý"
                          placeholder="Chọn người xử lý"
                          optionKey={(e) => e?.id}
                          optionLabel={(e) => (!e ? '' : `${e.name}`)}
                          onFetch={onFetchUser}
                        />
                      </Grid>
                    )}

                    {tab !== ListingTabType.MY_TICKET && (
                      <Grid item sm={6} xs={12}>
                        <FieldUserSelector
                          name="created_by"
                          labelOutline
                          label="Người tạo"
                          placeholder="Chọn người tạo"
                          optionKey={(e) => e?.id}
                          optionLabel={(e) => (!e ? '' : `${e.name}`)}
                          onFetch={onFetchUser}
                        />
                      </Grid>
                    )}
                    {tab !== ListingTabType.NEED_APPROVAL && (
                      <Grid item sm={6} xs={12}>
                        <FieldUserSelector
                          name="approve_by"
                          label="Người phê duyệt"
                          labelOutline
                          placeholder="Chọn người kiểm duyệt"
                          optionKey={(e) => e?.id}
                          optionLabel={(e) => (!e ? '' : `${e.name}`)}
                          onFetch={onFetchUser}
                        />
                      </Grid>
                    )}
                    <Grid item sm={6} xs={12}>
                      <FieldSelector
                        name="area"
                        label="Vùng"
                        labelOutline
                        placeholder="Chọn vùng"
                        optionKey={(e) => e?.id}
                        optionLabel={(e) => e?.name}
                        options={areas}
                        onSelected={(e) => {
                          props.setFieldValue('area', undefined)
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FieldCategorySelector
                        name="category"
                        label="Danh mục"
                        labelOutline
                        placeholder="Chọn loại danh mục"
                        optionKey={(e) => e?.id}
                        optionLabel={(e) => e?.name}
                        options={categories}
                        onSelected={(e) => {
                          props.setFieldValue('tag', undefined)
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FieldSelector
                        name="store"
                        label="Nơi phát sinh yêu cầu"
                        labelOutline
                        placeholder="Chọn nơi phát sinh yêu cầu"
                        optionKey={(e) => e?.id}
                        optionLabel={(e) => e?.name}
                        options={stores}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FieldSelector
                        name="tag"
                        label="Nhãn"
                        labelOutline
                        placeholder="Chọn nhãn"
                        optionKey={(e) => e?.id}
                        optionLabel={(e) => e?.name}
                        disabled={!props.values.category}
                        fetchOptions={{
                          pageSize: 100
                        }}
                        onFetch={(params) => onFetchTags(params, refForm.current.values?.category?.id)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} rowSpacing={2} pt={2}>
                    <Grid item sm={6}>
                      <FormControl>
                        <Label>{'Thời gian tạo'}</Label>
                        <Stack direction="row" alignItems="center">
                          <FieldDatePicker
                            name="created_at_gte"
                            labelOutline
                            placeholder="Từ ngày"
                            maxDate={props.values.created_at_lte}
                          />
                          <Typography px={1}>{'-'}</Typography>
                          <FieldDatePicker
                            name="created_at_lte"
                            labelOutline
                            placeholder="Từ ngày"
                            minDate={props.values.created_at_gte}
                          />
                        </Stack>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl>
                        <Label>{'Thời hạn xử lý'}</Label>
                        <Stack direction="row" alignItems="center">
                          <FieldDatePicker
                            name="deadline_gte"
                            labelOutline
                            placeholder="Từ ngày"
                            maxDate={props.values.deadline_lte}
                          />
                          <Typography px={1}>{'-'}</Typography>
                          <FieldDatePicker
                            name="deadline_lte"
                            labelOutline
                            placeholder="Từ ngày"
                            minDate={props.values.deadline_gte}
                          />
                        </Stack>
                      </FormControl>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Box display={'flex'} alignItems={'center'} width={'100%'}>
                    <Grid container justifyContent="space-between" rowSpacing={1}>
                      <Grid item>
                        <ButtonCus onClick={() => handleResetForm(props)}>{'Thiết lập lại bộ lọc'}</ButtonCus>
                      </Grid>
                      <Grid item>
                        <Stack direction="row" justifyContent={'flex-end'} spacing={1} style={{ flex: 1 }}>
                          <ButtonCus typeButton="secondary" onClick={() => toggleModal(false)}>
                            {'Hủy bỏ'}
                          </ButtonCus>
                          <ButtonCus
                            typeButton="primary"
                            loading={Boolean(props.isSubmitting)}
                            onClick={() => props?.handleSubmit?.()}
                          >
                            {'Áp dụng'}
                          </ButtonCus>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </DialogActions>
              </>
            )
          }}
        </Formik>
      </Modal>
    </>
  )
}
