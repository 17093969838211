import _ from 'lodash'
import { transformObjToUrlQuery } from 'utils/common'
import fetchApi from './config'

export const getList = async (filter) => {
  return await fetchApi(`/categories${transformObjToUrlQuery(filter)}`)
}

export const getAll = async (name = '') => {
  let query = `?${name}`
  return await fetchApi(`/categories${query}`)
}

export const getCategory = async (id) => {
  return await fetchApi(`/categories/${id}`)
}

export const createCategory = async (data) => {
  return await fetchApi(`/categories`, data, 'post')
}

export const updateCategory = async (id, data) => {
  return await fetchApi(`/categories/${id}`, data, 'put')
}

export const deleteCategory = async (id) => {
  return await fetchApi(`/categories/${id}`, {}, 'delete')
}

export const getTag = async (id) => {
  return await fetchApi(`/categories/tag/${id}`)
}

export const getCategoryTags = async (categoryId, filter) => {
  return await fetchApi(`/categories/${categoryId}/tags/${transformObjToUrlQuery({ ...filter })}`)
}

export const categoryCreateTag = async (categoryId, data) => {
  return await fetchApi(`/categories/${categoryId}/tags`, data, 'post')
}

export const categoryUpdateTag = async (categoryId, tagId, data) => {
  return await fetchApi(`/categories/${categoryId}/tags/${tagId}`, data, 'put')
}

export const categoryDeleteTag = async (categoryId, tagId) => {
  return await fetchApi(`/categories/${categoryId}/tags/${tagId}`, {}, 'delete')
}
