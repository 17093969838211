import TextField from '@mui/material/TextField'
import { Field } from 'formik'
import React, { useRef } from 'react'
import { Selector } from './form'
import UserInfoList from './UserInfoList'
import UiAvatar from './ui/UiAvatar'
import { Avatar, Box, InputAdornment, Stack, Tooltip } from '@mui/material'
import { Text } from './ui'
import CheckIcon from '@mui/icons-material/Check'

/**
 * @template T
 * @template IsMulti
 * @typedef {object} ISelectorProps
 * @property {string} [label]
 * @property {boolean} [requiredLabel]
 * @property {string} [placeholder]
 * @property {boolean} [labelOutline]
 * @property {FormControlProps} [propsContainer]
 * @property {T} [selected]
 * @property {T[]} [options]
 * @property {(e?:T, index: number)=>string|number} [optionKey]
 * @property {(e?:T, index: number)=>string|number} [optionLabel]
 * @property {(e?:T, index: number)=>string|number} [optionFilter]
 * @property {(e?:T)=>string|number} [onSelected]
 * @property {(params:{search: string, page: number, pageSize: number})=>{rows: T[], total: number} | Promise<{rows: T[], total: number}[]>} [onFetch]
 * @property {object} [fetchOptions]
 * @property {number} [fetchOptions.initPage]
 * @property {number} [fetchOptions.pageSize]
 */
/**
 * @param {ISelectorProps<T, IsMulti> & FieldProps} props
 * @returns {React.ReactNode}
 */
export const CategorySelector = (props) => {
  const {} = props

  return (
    <Selector
      {...props}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          style={{
            background: selected ? '#EFF6FF' : '',
            '&:hover': {
              background: '#EFF6FF',
            },
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          <Box
            style={{
              width: '100%',
              borderBottom: '1px solid #EEEEEE',
              padding: '13px 26px 13px 0px',
              position: 'relative',
            }}
          >
            <Text style={{ marginBottom: '4px' }} color="#333333">
              {option.description}
            </Text>
            <Text
              style={{
                fontSize: '12px',
                color: '#737373',
              }}
            >
              {option.name}
            </Text>
            {selected && (
              <CheckIcon
                style={{
                  position: 'absolute',
                  right: '0px',
                  top: 'calc(50% - 12px)',
                }}
                color="primary"
              />
            )}
          </Box>
        </li>
      )}
    />
  )
}

/**
 * @param {ISelectorProps<T, IsMulti> & FieldConfig} props
 * @returns
 */
export const FieldCategorySelector = (props) => <Field {...props} component={CategorySelector} />
