import { CircularProgress, FormControl, FormControlProps } from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { debounce } from '@mui/material/utils'
import { Field, FieldConfig, FieldProps } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { Label } from '../ui/Label'
import { LabelError } from './LabelError'

const debounce1 = (func, wait = 166) => {
  let timeout
  const debounced = (...args) => {
    const later = () => {
      func.apply(this, args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
  debounced.clear = () => {
    clearTimeout(timeout)
  }
  return debounced
}

/**
 * @template T
 * @template IsMulti
 * @typedef {object} ISelectorProps
 * @property {string} [label]
 * @property {boolean} [requiredLabel]
 * @property {string} [placeholder]
 * @property {boolean} [labelOutline]
 * @property {FormControlProps} [propsContainer]
 * @property {T} [selected]
 * @property {T[]} [options]
 * @property {(e?:T, index: number)=>string|number} [optionKey]
 * @property {(e?:T, index: number)=>string|number} [optionLabel]
 * @property {(e?:T, index: number)=>string|number} [optionFilter]
 * @property {(e?:T)=>string|number} [onSelected]
 * @property {(params:{search: string, page: number, pageSize: number})=>{rows: T[], total: number} | Promise<{rows: T[], total: number}[]>} [onFetch]
 * @property {object} [fetchOptions]
 * @property {number} [fetchOptions.initPage]
 * @property {number} [fetchOptions.pageSize]
 */
/**
 * @param {ISelectorProps<T, IsMulti> & FieldProps} props
 * @returns {React.ReactNode}
 */
export const Selector = (props) => {
  const {
    label,
    requiredLabel,
    disabled = false,
    labelOutline = true,
    placeholder = '',
    selected: _selected,
    options: _options = [],
    optionKey = (e, index) => index,
    optionLabel = (e, index) => `Item ${index + 1}`,
    optionFilter,
    onSelected,
    onFetch: _onFetch,
    fetchOptions: { initPage = 1, pageSize = 10 } = {},
    propsContainer,
    form,
    field,
  } = props

  const params = useRef({
    search: '',
    page: initPage,
    pageSize: pageSize,
    total: 0,
  })

  const [options, setOptions] = useState(_options)
  const [isLoading, setIsLoading] = useState(false)
  const [selected, setSelected] = useState(field !== undefined ? field.value : _selected)

  const fetchDebounce = React.useMemo(
    () =>
      debounce(async (params, callback) => {
        const d = await _onFetch(params)
        callback(d, params)
      }, 400),
    []
  )

  useEffect(() => {
    setOptions(_options)
  }, [JSON.stringify(_options)])

  useEffect(() => {
    setSelected(field !== undefined ? field.value : _selected)
  }, [_selected, field])

  const onFetch = () => {
    if (!_onFetch) return

    setIsLoading(true)

    fetchDebounce(params.current, (d, p) => {
      const { rows, total } = d
      params.current = { ...params.current, ...p, total }
      setIsLoading(false)
      setOptions(p.page === initPage ? rows : [...options, ...rows])
    })
  }

  const onFetchMore = () => {
    if (!_onFetch || isLoading || (params.current.total <= options.length && options.length !== 0)) return
    params.current = { ...params.current, page: params.current.page + 1 }
    onFetch()
  }

  const onInputChange = async (value) => {
    if (_onFetch !== undefined /*  && params.current.search !== value */) {
      params.current = { ...params.current, page: initPage, search: value }
      onFetch()
    }
  }

  const onChange = (value) => {
    setSelected(value)

    onSelected && onSelected(value)

    if (form !== undefined) form.setFieldValue(field.name, value)
  }

  const onOpenPopup = () => {
    if (params.current.search == '' && options.length == 0) onFetch()
  }

  const onClosePopup = () => {
    if (_onFetch !== undefined) {
      params.current = {
        page: initPage,
        pageSize: pageSize,
        search: '',
        total: 0,
      }
      setOptions([])
    }
  }

  const touched = form?.touched[field.name]
  const error = form?.errors[field.name]
  const isError = touched === true && !!error
  return (
    <FormControl fullWidth {...propsContainer} error={isError}>
      {label && labelOutline === true && <Label required={requiredLabel}>{label}</Label>}
      <Autocomplete
        size="small"
        options={options}
        value={selected === undefined ? null : selected}
        onOpen={onOpenPopup}
        onClose={onClosePopup}
        isOptionEqualToValue={(e, value) => optionKey(e) === optionKey(value)}
        getOptionLabel={optionLabel}
        loading={isLoading}
        disabled={disabled}
        onChange={(e, value) => onChange(value)}
        {...(optionFilter !== undefined && {
          filterOptions: createFilterOptions({
            matchFrom: 'any',
            stringify: (e) => optionFilter(e),
          }),
        })}
        renderInput={(_params) => (
          <TextField
            {..._params}
            InputProps={{
              ..._params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {_params.InputProps.endAdornment}
                </React.Fragment>
              ),
              value: params.current?.search,
            }}
            {...(!labelOutline && { label: label })}
            error={isError}
            placeholder={placeholder}
          />
        )}
        onInputChange={(event, newInputValue, reason) => {
          reason !== 'reset' && onInputChange(newInputValue)
        }}
        includeInputInList
        ListboxProps={{
          onScroll: (event) => {
            const listboxNode = event.currentTarget
            if (Math.abs(listboxNode.scrollTop + listboxNode.clientHeight - listboxNode.scrollHeight) <= 10) {
              onFetchMore()
            }
          },
        }}
        {...props}
      />
      {isError && <LabelError>{error}</LabelError>}
    </FormControl>
  )
}

/**
 * @param {ISelectorProps<T, IsMulti> & FieldConfig} props
 * @returns
 */
export const FieldSelector = (props) => <Field {...props} component={Selector} />
