export default class UserRoleLevel {
  static SUPERADMIN = 1
  static COORDINATOR = 2
  static EMPLOYEE = 3
  static AREA_MANAGER = 4
  static STORE_MANAGER = 5
}

UserRoleLevel[UserRoleLevel.SUPERADMIN] = 'Quản trị viên'
UserRoleLevel[UserRoleLevel.COORDINATOR] = 'Điều phối viên'
UserRoleLevel[UserRoleLevel.EMPLOYEE] = 'Nhân viên'
UserRoleLevel[UserRoleLevel.AREA_MANAGER] = 'Quản lý vùng'
UserRoleLevel[UserRoleLevel.STORE_MANAGER] = 'Quản lý cửa hàng'
