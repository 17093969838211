import { useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

useOnClickOutside.propTypes = {
  handler: PropTypes.func,
}

function useOnClickOutside(ref, handler) {
  const handleListening = (currentRef) => {
    const listener = (event) => {
      if (!currentRef?.current || currentRef?.current?.contains(event.target)) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener, { capture: true })
    document.addEventListener('touchstart', listener, { capture: true })
    return () => {
      document.removeEventListener('mousedown', listener, { capture: true })
      document.removeEventListener('touchstart', listener, { capture: true })
    }
  }

  useEffect(() => {
    if (_.isArray(ref)) {
      ref.forEach((item) => handleListening(item))
    } else {
      handleListening(ref)
    }
  }, [ref, handler])
}

export default useOnClickOutside
