import { Box, Tab, Tabs } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import UserRoleLevel from 'constants/user-role-level'
import PropTypes from 'prop-types'
import React from 'react'
import ListingTabType from '../constants/tab-type'
import { isAreaManager, isCoordinator, isHandler, isStoreManager } from '../utils'

ListingFilterTab.propTypes = {
  userInfo: PropTypes.object,
  onChange: PropTypes.func,
}

ListingFilterTab.defaultProps = {
  userInfo: {},
  onChange: () => {},
}

function ListingFilterTab({ value, onChange, userInfo }) {
  const theme = useTheme()
  const handleChangeTab = (val) => {
    onChange(val)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        visibleScrollbar
        sx={{
          '& .MuiTabs-scroller': {
            overflow: 'auto',
          },
        }}
        allowScrollButtonsMobile
        value={value}
        onChange={(e, val) => handleChangeTab(val)}
        selectionFollowsFocus
      >
        <Tab
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '13px',
              '&.Mui-selected': {
                fontSize: '13px',
              },
            },
          }}
          value={ListingTabType.MY_TICKET}
          label={ListingTabType[ListingTabType.MY_TICKET]}
          style={{ fontWeight: 500 }}
        />
        {isHandler(userInfo) && (
          <Tab
            sx={{
              [theme.breakpoints.down('md')]: {
                fontSize: '13px',
                '&.Mui-selected': {
                  fontSize: '13px',
                },
              },
            }}
            value={ListingTabType.HANDLE_BY_ME}
            label={ListingTabType[ListingTabType.HANDLE_BY_ME]}
            style={{ fontWeight: 500 }}
          />
        )}
        {isCoordinator(userInfo) || isAreaManager(userInfo) || isStoreManager(userInfo) ? (
          <Tab
            sx={{
              [theme.breakpoints.down('md')]: {
                fontSize: '13px',
                '&.Mui-selected': {
                  fontSize: '13px',
                },
              },
            }}
            value={ListingTabType.MANAGED_BY_ME}
            label={ListingTabType[ListingTabType.MANAGED_BY_ME]}
            style={{ fontWeight: 500 }}
          />
        ) : null}
        <Tab
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '13px',
              '&.Mui-selected': {
                fontSize: '13px',
              },
            },
          }}
          value={ListingTabType.NEED_APPROVAL}
          label={ListingTabType[ListingTabType.NEED_APPROVAL]}
          style={{ fontWeight: 500 }}
        />
      </Tabs>
    </Box>
  )
}

export default ListingFilterTab
