import { Chip } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import TicketApproveStatusType from 'constants/ticket-approve-status-type'
import * as React from 'react'

/**
 * @typedef {object} IProps
 * @property {number} [status]
 */
/**
 * @param {IProps & React.PropsWithChildren} props
 * @returns
 */
export const TicketApproveChip = (props) => {
  const theme = useTheme()
  const {
    status,
  } = props

  const config = status >= 0 ? TicketApproveStatusType[status] : undefined
  return config !== undefined && (
    <Chip
      label={config['text']}
      variant='filled'
      sx={{
        '& span': {
          fontSize: '14px',
        },
        [theme.breakpoints.down('md')]: {
          '& span': {
            fontSize: '13px',
          },
        },
        height: '24px', fontWeight: 'bold',
      }}
      style={{
        borderRadius: 3,
        ...config['bgcolor'] && { background: config['bgcolor'] },
        ...config['color'] && { color: config['color'] },
      }}
    />
  )
}