import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import { isString } from 'lodash'
import { Box } from '@mui/system'

MessageBox.propTypes = {
  title: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  showIcon: PropTypes.bool,
  style: PropTypes.object,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
}

MessageBox.defaultProps = {
  title: '',
  onOpen: () => {
  },
  onClose: () => {
  },
  showIcon: true,
  style: {},
  maxWidth: 'md',
  minWidth: 700,
}

function MessageBox({ title, onClose, showIcon, onOpen, children, style, maxWidth, minWidth }) {
  return (
    <Dialog open={true} onClose={() => onClose()} maxWidth={maxWidth} style={style}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          padding: '16px',
        }}
      >
        {title && (
          <DialogTitle sx={{ padding: 0, flex: 1, textAlign: 'center' }}>
            <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>{title}</Typography>
          </DialogTitle>
        )}
        <ClearOutlinedIcon sx={{ color: '#999999', cursor: 'pointer' }} onClick={() => onClose()} />
      </Box>
      <DialogContent
        dividers
        sx={{
          minWidth: 852,
          maxHeight: 600,
          padding: 2,
        }}
      >
        {isString(children) ? (
          <span
            dangerouslySetInnerHTML={{
              __html: children,
            }}
          ></span>
        ) : (
          children
        )}
      </DialogContent>
    </Dialog>
  )
}

export default MessageBox
