import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { formatTicketFilter } from 'pages/ticket/utils'
import { transformObjToUrlQuery } from 'utils/common'
import * as ticketService from 'services/ticket.service'
import { isObject } from 'lodash'

export const fetchTickets = createAsyncThunk('tickets/getTickets', async (filter) => {
  console.log('filter', filter)

  let { origin, pathname } = window.location
  window.history.pushState({}, '', `${origin}${pathname}${transformObjToUrlQuery(formatTicketFilter(filter))}`)

  return await ticketService.getTickets(formatTicketFilter(filter))
})

export const exportTickets = createAsyncThunk('tickets/exportTickets', async (filter) => {
  return await ticketService.getTickets(
    formatTicketFilter({
      ...filter,
      export: true,
    })
  )
})

export const deleteTicket = createAsyncThunk('tickets/deleteTicket', async (id) => {
  return await ticketService.deleteTicket(id)
})

const initialState = {
  loadingData: false,
  loadingExport: false,
  loading: 'idle' | 'pending' | 'succeeded' | 'failed',
  tickets: [],
  keyRefetch: '',
}

const ticketSlice = createSlice({
  initialState,
  name: 'ticket',
  reducers: {
    changeKeyRefetch: (state, action) => {
      state.keyRefetch = action.payload
    },
  },
  extraReducers: {
    [fetchTickets.pending]: (state, action) => {
      state.loadingData = true
    },
    [fetchTickets.fulfilled]: (state, action) => {
      if (isObject(action.payload)) {
        state.tickets = action.payload
      }
      state.loadingData = false
    },
    [fetchTickets.rejected]: (state, action) => {
      state.loadingData = false
    },
    [exportTickets.pending]: (state, action) => {
      state.loadingExport = true
    },
    [exportTickets.fulfilled]: (state, action) => {
      state.loadingExport = false

      console.log('actionaction', action)

      if (action.payload.indexOf('.csv') > -1) {
        window.open(`${action.payload}`, '_blank')
      } else {
        window.open(`https://view.officeapps.live.com/op/view.aspx?src=${action.payload}`, '_blank')
      }
    },
    [exportTickets.rejected]: (state, action) => {
      state.loadingExport = false
    },
    changeKeyRefetch: (state, action) => {
      state.keyRefetch = action.payload
    },
  },
  extraReducers: {
    [fetchTickets.pending]: (state, action) => {
      state.loadingData = true
    },
    [fetchTickets.fulfilled]: (state, action) => {
      if (isObject(action.payload)) {
        state.tickets = action.payload
      }
      state.loadingData = false
    },
    [fetchTickets.rejected]: (state, action) => {
      state.loadingData = false
    },
    [exportTickets.pending]: (state, action) => {
      state.loadingExport = true
    },
    [exportTickets.fulfilled]: (state, action) => {
      state.loadingExport = false

      if (action.payload.indexOf('.csv') > -1) {
        window.open(`${action.payload}`, '_blank')
      } else {
        window.open(`https://view.officeapps.live.com/op/view.aspx?src=${action.payload}`, '_blank')
      }
    },
    [exportTickets.rejected]: (state, action) => {
      state.loadingExport = false
    },
  },
})

export const { actions: ticketActions, reducer: ticketReducer } = ticketSlice
