import { FormControl, TextField, TextFieldProps } from '@mui/material'
import { Text } from 'components/ui'
import { Field, FieldConfig, FieldProps } from 'formik'
import { useEffect, useState } from 'react'
import { Label } from '../ui/Label'
import { LabelError } from './LabelError'

/**
 * @typedef {object} ITextInputProps
 * @property {string} [label]
 * @property {boolean} [requiredLabel]
 * @property {string} [placeholder]
 * @property {string} [description]
 * @property {boolean} [labelOutline]
 * @property {FormControlProps} [propsContainer]
 * @property {string} [value]
 */
/**
 * @param {ITextInputProps & TextFieldProps & FieldProps & React.PropsWithChildren} props
 * @returns
 */
export const TextInput = (props) => {
  const {
    label,
    requiredLabel,
    placeholder,
    labelOutline = true,
    propsContainer,
    value: _value,
    description,

    field,
    form,

    ...rest
  } = props

  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(field ? field.value : _value)
  }, [field?.value, _value])

  const onChange = (v) => {
    if (field) form.setFieldValue(field.name, v)
    else setValue(v)
  }

  const touched = form?.touched[field.name]
  const error = form?.errors[field.name]
  const isError = touched === true && !!error
  rest.value = field ? field.value : _value

  return (
    <FormControl fullWidth {...propsContainer} error={isError}>
      {label && labelOutline === true && <Label required={requiredLabel}>{label}</Label>}
      <TextField
        {...rest}
        onChange={(e) => onChange(e.target.value)}
        fullWidth
        error={isError}
        size="small"
        {...(!labelOutline && { label: label })}
        placeholder={placeholder}
      />
      {description && (
        <Text fontSize={14} color={'#999999'}>
          {description}
        </Text>
      )}
      {isError && <LabelError>{error}</LabelError>}
    </FormControl>
  )
}

/**
 * @param {ITextInputProps & TextFieldProps & FieldConfig} props
 * @returns
 */
export const FieldTextInput = (props) => <Field {...props} component={TextInput} />
