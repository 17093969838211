import TextField from '@mui/material/TextField'
import { Field } from 'formik'
import React, { useRef } from 'react'
import { Selector } from './form'
import UserInfoList from './UserInfoList'
import UiAvatar from './ui/UiAvatar'
import { Avatar, Box, InputAdornment, Stack, Tooltip } from '@mui/material'
import { Text } from './ui'
import CheckIcon from '@mui/icons-material/Check'

/**
 * @template T
 * @template IsMulti
 * @typedef {object} ISelectorProps
 * @property {string} [label]
 * @property {boolean} [requiredLabel]
 * @property {string} [placeholder]
 * @property {boolean} [labelOutline]
 * @property {FormControlProps} [propsContainer]
 * @property {T} [selected]
 * @property {T[]} [options]
 * @property {(e?:T, index: number)=>string|number} [optionKey]
 * @property {(e?:T, index: number)=>string|number} [optionLabel]
 * @property {(e?:T, index: number)=>string|number} [optionFilter]
 * @property {(e?:T)=>string|number} [onSelected]
 * @property {(params:{search: string, page: number, pageSize: number})=>{rows: T[], total: number} | Promise<{rows: T[], total: number}[]>} [onFetch]
 * @property {object} [fetchOptions]
 * @property {number} [fetchOptions.initPage]
 * @property {number} [fetchOptions.pageSize]
 */
/**
 * @param {ISelectorProps<T, IsMulti> & FieldProps} props
 * @returns {React.ReactNode}
 */
export const UserSelector = (props) => {
  const {
    isReadonly = false,
    value = null,
    label,
    labelOutline = true,
    placeholder = '',
    fetchOptions: { initPage = 1, pageSize = 10 } = {},
    form,
    field,
    disabled = false,
  } = props

  const params = useRef({
    search: '',
    page: initPage,
    pageSize: pageSize,
    total: 0,
  })

  const touched = form?.touched[field.name]
  const error = form?.errors[field.name]
  const isError = touched === true && !!error

  return (
    <React.Fragment>
      {isReadonly ? (
        <TextField
          {...props}
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <UiAvatar
                  name={value?.name}
                  src={value?.avatar}
                  style={{ width: 18, height: 18, marginRight: '8px' }}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {value ? (
                  <InputAdornment>
                    <Tooltip {...props} arrow title={<UserInfoList data={value} style={{ width: 18, height: 18 }} />}>
                      <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.9987 14.6771C4.04536 14.6771 0.832031 11.4637 0.832031 7.51042C0.832031 3.55708 4.04536 0.34375 7.9987 0.34375C11.952 0.34375 15.1654 3.55708 15.1654 7.51042C15.1654 11.4637 11.952 14.6771 7.9987 14.6771ZM7.9987 1.34375C4.5987 1.34375 1.83203 4.11042 1.83203 7.51042C1.83203 10.9104 4.5987 13.6771 7.9987 13.6771C11.3987 13.6771 14.1654 10.9104 14.1654 7.51042C14.1654 4.11042 11.3987 1.34375 7.9987 1.34375ZM7.9987 8.67708C7.72536 8.67708 7.4987 8.45042 7.4987 8.17708V4.84375C7.4987 4.57042 7.72536 4.34375 7.9987 4.34375C8.27203 4.34375 8.4987 4.57042 8.4987 4.84375V8.17708C8.4987 8.45042 8.27203 8.67708 7.9987 8.67708ZM7.74536 10.7903C7.82536 10.8237 7.91203 10.8437 7.9987 10.8437C8.08536 10.8437 8.17203 10.8237 8.25203 10.7903C8.33203 10.757 8.40536 10.7103 8.47203 10.6503C8.53203 10.5837 8.5787 10.517 8.61203 10.4303C8.64536 10.3503 8.66536 10.2637 8.66536 10.177C8.66536 10.0903 8.64536 10.0037 8.61203 9.92367C8.5787 9.84367 8.53203 9.77034 8.47203 9.70367C8.40536 9.64367 8.33203 9.597 8.25203 9.56367C8.09203 9.49701 7.90536 9.49701 7.74536 9.56367C7.66536 9.597 7.59203 9.64367 7.52536 9.70367C7.46536 9.77034 7.4187 9.84367 7.38536 9.92367C7.35203 10.0037 7.33203 10.0903 7.33203 10.177C7.33203 10.2637 7.35203 10.3503 7.38536 10.4303C7.4187 10.517 7.46536 10.5837 7.52536 10.6503C7.59203 10.7103 7.66536 10.757 7.74536 10.7903Z"
                          fill="#2560E5"
                        />
                      </svg>
                    </Tooltip>
                  </InputAdornment>
                ) : null}
              </React.Fragment>
            ),
          }}
          value={value?.name}
          disabled={true}
        />
      ) : (
        <Selector
          {...props}
          renderInput={(_params) => {
            let InputProps = {
              ..._params.InputProps,
              startAdornment: (
                <React.Fragment>
                  <UiAvatar
                    name={field.value?.name}
                    src={field.value?.avatar}
                    style={{ width: 18, height: 18, marginRight: '8px' }}
                  />
                </React.Fragment>
              ),
              value: params.current?.search,
            }

            if (disabled) {
              InputProps = {
                ...InputProps,
                endAdornment: (
                  <React.Fragment>
                    {value ? (
                      <Tooltip
                        {...props}
                        arrow
                        title={<UserInfoList data={field.value} style={{ width: 18, height: 18 }} />}
                      >
                        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.9987 14.6771C4.04536 14.6771 0.832031 11.4637 0.832031 7.51042C0.832031 3.55708 4.04536 0.34375 7.9987 0.34375C11.952 0.34375 15.1654 3.55708 15.1654 7.51042C15.1654 11.4637 11.952 14.6771 7.9987 14.6771ZM7.9987 1.34375C4.5987 1.34375 1.83203 4.11042 1.83203 7.51042C1.83203 10.9104 4.5987 13.6771 7.9987 13.6771C11.3987 13.6771 14.1654 10.9104 14.1654 7.51042C14.1654 4.11042 11.3987 1.34375 7.9987 1.34375ZM7.9987 8.67708C7.72536 8.67708 7.4987 8.45042 7.4987 8.17708V4.84375C7.4987 4.57042 7.72536 4.34375 7.9987 4.34375C8.27203 4.34375 8.4987 4.57042 8.4987 4.84375V8.17708C8.4987 8.45042 8.27203 8.67708 7.9987 8.67708ZM7.74536 10.7903C7.82536 10.8237 7.91203 10.8437 7.9987 10.8437C8.08536 10.8437 8.17203 10.8237 8.25203 10.7903C8.33203 10.757 8.40536 10.7103 8.47203 10.6503C8.53203 10.5837 8.5787 10.517 8.61203 10.4303C8.64536 10.3503 8.66536 10.2637 8.66536 10.177C8.66536 10.0903 8.64536 10.0037 8.61203 9.92367C8.5787 9.84367 8.53203 9.77034 8.47203 9.70367C8.40536 9.64367 8.33203 9.597 8.25203 9.56367C8.09203 9.49701 7.90536 9.49701 7.74536 9.56367C7.66536 9.597 7.59203 9.64367 7.52536 9.70367C7.46536 9.77034 7.4187 9.84367 7.38536 9.92367C7.35203 10.0037 7.33203 10.0903 7.33203 10.177C7.33203 10.2637 7.35203 10.3503 7.38536 10.4303C7.4187 10.517 7.46536 10.5837 7.52536 10.6503C7.59203 10.7103 7.66536 10.757 7.74536 10.7903Z"
                            fill="#2560E5"
                          />
                        </svg>
                      </Tooltip>
                    ) : null}
                  </React.Fragment>
                ),
              }
            }

            return (
              <TextField
                {..._params}
                InputProps={InputProps}
                {...(!labelOutline && { label: label })}
                error={isError}
                placeholder={placeholder}
              />
            )
          }}
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              style={{
                background: selected ? '#EFF6FF' : '',
                '&:hover': {
                  background: '#EFF6FF',
                },
                paddingBottom: 0,
                paddingTop: 0,
                position: 'relative',
              }}
            >
              <Stack direction="row" spacing="10px" alignItems="center">
                <UiAvatar name={option.name} src={option.avatar} size={44} />
                <Box
                  style={{
                    width: '100%',
                    padding: '10px 0 10px 0',
                  }}
                >
                  <Text style={{ marginBottom: '4px' }} color="#333333">
                    {option.name}
                  </Text>
                  <Text
                    style={{
                      fontSize: '12px',
                      color: '#737373',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {option.company} - {option.username}
                  </Text>
                </Box>
                {selected && (
                  <CheckIcon
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: 'calc(50% - 12px)',
                    }}
                    color="primary"
                  />
                )}
              </Stack>
            </li>
          )}
        />
      )}
    </React.Fragment>
  )
}

/**
 * @param {ISelectorProps<T, IsMulti> & FieldConfig} props
 * @returns
 */
export const FieldUserSelector = (props) => <Field {...props} component={UserSelector} />
