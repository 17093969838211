export default class TicketApproveStatusType {
  static NONEED = -1
  static PENDING = 0
  static APPROVED = 1
  static REJECTED = 2
}

TicketApproveStatusType[TicketApproveStatusType.NONEED] = { text: 'Không cần phê duyệt', color: '#525252', }
TicketApproveStatusType[TicketApproveStatusType.PENDING] = { text: 'Yêu cầu phê duyệt', color: '#D97706', bgcolor: '#FDE68A' }
TicketApproveStatusType[TicketApproveStatusType.APPROVED] = { text: 'Đã phê duyệt', color: '#0284C7', bgcolor: '#E0F2FE' }
TicketApproveStatusType[TicketApproveStatusType.REJECTED] = { text: 'Từ chối phê duyệt', color: '#DB2777', bgcolor: '#FCE7F3' }
