import { Chip } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import TicketStatusType from 'constants/ticket-status-type'
import * as React from 'react'

/**
 * @typedef {object} IProps
 * @property {number} [status]
 */
/**
 * @param {IProps & React.PropsWithChildren} props
 * @returns
 */
export const TicketStatusChip = (props) => {
  const theme = useTheme()
  const { status } = props

  const config = TicketStatusType[status]
  return (
    config !== undefined && (
      <Chip
        label={config['text']}
        variant='filled'
        sx={{
          '& span': {
            fontSize: '14px',
          },
          [theme.breakpoints.down('md')]: {
            '& span': {
              fontSize: '13px',
            },
          },
          height: '24px',
          fontWeight: 'bold',
        }}
        style={{
          borderRadius: 3,
          ...(config['bgcolor'] && { background: config['bgcolor'] }),
          ...(config['color'] && { color: config['color'] }),
        }}
      />
    )
  )
}
