import { MESSAGE_TYPES } from 'constants/common'
import { isEmpty, isString } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { getArea } from 'services/area.service'
import { getCategory, getTag } from 'services/category.service'
import { getStore } from 'services/store.service'
import { getUser2 } from 'services/user.service'

const useInitialFilter = () => {
  const [searchParams] = useSearchParams()

  let loadFilter = useCallback(async () => {
    let params = {}

    for (const entry of searchParams.entries()) {
      params[entry[0]] = entry[1]
    }

    let initialFilter = {
      ...params,
    }

    let promises = []

    if (!isEmpty(initialFilter)) {
      if (isString(initialFilter?.assignee)) {
        promises.push(getUser2(initialFilter.assignee))
      } else {
        promises.push(Promise.resolve())
      }

      if (isString(initialFilter?.raise_ticket_by)) {
        promises.push(getUser2(initialFilter.raise_ticket_by))
      } else {
        promises.push(Promise.resolve())
      }

      if (isString(initialFilter?.approve_by)) {
        promises.push(getUser2(initialFilter.approve_by))
      } else {
        promises.push(Promise.resolve())
      }

      if (isString(initialFilter?.created_by)) {
        promises.push(getUser2(initialFilter.created_by))
      } else {
        promises.push(Promise.resolve())
      }

      if (isString(initialFilter?.area)) {
        promises.push(getArea(initialFilter.area))
      } else {
        promises.push(Promise.resolve())
      }

      if (isString(initialFilter?.category)) {
        promises.push(getCategory(initialFilter.category))
      } else {
        promises.push(Promise.resolve())
      }

      if (isString(initialFilter?.store)) {
        promises.push(getStore(initialFilter.store))
      } else {
        promises.push(Promise.resolve())
      }

      if (isString(initialFilter?.category && initialFilter?.tag)) {
        promises.push(getTag(initialFilter.tag))
      } else {
        promises.push(Promise.resolve())
      }
    }

    if (promises.length > 0) {
      let res = await Promise.all(promises)

      const [assignee, raise_ticket_by, approve_by, created_by, area, category, store, tag] = res

      return {
        ...initialFilter,
        assignee,
        raise_ticket_by,
        approve_by,
        area,
        category,
        store,
        tag: tag,
        created_by,
      }
    } else {
      return {}
    }
  }, [])

  return loadFilter()
}

export default useInitialFilter
