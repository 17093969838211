import _ from 'lodash'
import { transformObjToUrlQuery } from 'utils/common'
import fetchApi from './config'

export const getUserToken = async () => {
  return await fetchApi('/users/me')
}

export const getUsers = async (data = {}) => {
  return await fetchApi(`/users/${transformObjToUrlQuery(data)}`)
}

export const getUser = async (id) => {
  return await fetchApi(`/admin/users/${id}`)
}

export const getUser2 = async (id) => {
  return await fetchApi(`/users/${id}`)
}

export const updateUser = async (id, data) => {
  return await fetchApi(`/users/${id}`, data, 'put')
}

export const adminGetUsers = async (data) => {
  return await fetchApi(`/admin/users${transformObjToUrlQuery(data)}`)
}

export const adminGetUser = async (id) => {
  return await fetchApi(`/admin/users/${id}`)
}

export const adminUpdateUser = async (id, data) => {
  return await fetchApi(`/admin/users/${id}`, data, 'put')
}

export const notifications = async (data = {}) => {
  return await fetchApi(`/users/notifications/${transformObjToUrlQuery(data)}`)
}

export const getNotification = async (id) => {
  return await fetchApi(`/users/notifications/${id}`)
}

export const updateProfile = async (data) => {
  return await fetchApi(`/users/me`, data, 'put')
}
