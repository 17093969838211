import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

/**
 * @typedef {object} ITextProps
 */
/**
 * @param {TypographyProps & React.PropsWithChildren} props 
 * @returns 
 */
export const Text = (props) => {
    const {
        ...rest
    } = props;

    return (<Typography color='#525252' {...rest} />)
}
