import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import useAuth from 'hooks/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import useAuthorize from 'hooks/useAuthorize'

const ProtectedAuthorize = ({ allowedRoles }) => {
  const isAuthorized = useAuthorize(allowedRoles)

  return isAuthorized ? <Outlet /> : <Navigate to="/tickets" />
}

ProtectedAuthorize.propTypes = {
  allowedRoles: PropTypes.array,
}

export default ProtectedAuthorize
