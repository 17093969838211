import { FormControl, FormControlProps, FormControlLabel, FormControlLabelProps, FormHelperText, RadioGroup as RadioGroupMUI, Radio as RadioMUI } from '@mui/material';
import { Field, FieldConfig, FieldProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { Label } from '../ui/Label';
import { LabelError } from './LabelError';

/**
 * 
 * @param {object} props 
 * @param {string} [props.label]
 * @param {string|number} [props.value]
 * @returns 
 */
const RadioItem = ({ label, value, ...props }) => (<FormControlLabel {...props} value={value} control={<RadioMUI />} label={label} />)

/**
 * @template T
 * @typedef {object} IRadioGroupProps
 * @property {string} [label]
 * @property {boolean} [requiredLabel]
 * @property {boolean} [labelOutline]
 * @property { T[] } options
 * @property {(e?:T, index: number)=>string|number} [optionKey]
 * @property {(e?:T, index: number)=>string|number} [optionLabel]
 * @property {(e?:T, index: number)=>string} [optionName]
 * @property {string|number} [value]
 * @property {(value: string|number)=> void} [onChange]
 * @property {FormControlLabelProps} [propsRadio]
 * @property {(props: { value?: number | string, error?: string, Radio: { [name: string]: React.ReactNode } } )=>React.ReactNode} [renderRadio]
 * @property {FormControlProps} [propsContainer]
 */
/**
 * @template T
 * @param {IRadioGroupProps<T> & FieldProps & React.PropsWithChildren} props 
 * @returns 
 */
export const RadioGroup = (props) => {
    const {
        label,
        requiredLabel,
        options,
        optionKey = (e, index) => index,
        optionLabel = (e, index) => `Index ` + (index + 1),
        optionName = (e, index) => undefined,
        value: _value,
        propsRadio,
        onChange: _onChange,
        renderRadio,
        propsContainer,

        field,
        form
    } = props;

    const [value, setValue] = useState(_value);

    useEffect(() => {
        setValue(field ? field.value : _value)
    }, [field?.value, _value]);

    const onChange = (event) => {
        const v = event?.target?.value;
        if (form)
            form.setFieldValue(field.name, v)
        else
            setValue(v)

        _onChange?.(v);
    }

    const optionsRadio = Object.fromEntries((options || []).map((e, index) => {
        const label = optionLabel(e, index);
        const value = optionKey(e, index);
        const name = optionName(e, index) || e.name || 'Radio' + (index + 1);
        return [
            name,
            React.createElement(
                RadioItem,
                {
                    ...propsRadio,
                    key: index,
                    label: label,
                    value
                }
            )
        ]
    }))

    const render = () => {
        if (renderRadio == null)
            return Object.entries(optionsRadio).map(([k, component]) => component);
        return renderRadio({
            value: value,
            error: '',
            Radio: optionsRadio
        });
    }

    const touched = form?.touched[field.name];
    const error = form?.errors[field.name];
    const isError = touched === true && !!error;
    return (
        <FormControl fullWidth {...propsContainer}>
            {label && <Label required={requiredLabel}>{label}</Label>}
            <RadioGroupMUI
                value={value === undefined ? null : value}
                onChange={onChange}
            >
                {render()}
            </RadioGroupMUI>
            {isError && <LabelError>{error}</LabelError>}
        </FormControl>
    )

}

/**
 * @template T
 * @param {IRadioGroupProps<T> & FieldConfig} props 
 * @returns 
 */
export const FieldRadioGroup = (props) => <Field {...props} component={RadioGroup} />