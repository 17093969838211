export default class ListingTabType {
  static MY_TICKET = 1
  static HANDLE_BY_ME = 2
  static MANAGED_BY_ME = 3
  static NEED_APPROVAL = 4
}

ListingTabType[ListingTabType.MY_TICKET] = ' Yêu cầu của tôi'
ListingTabType[ListingTabType.HANDLE_BY_ME] = 'Xử lý bởi tôi'
ListingTabType[ListingTabType.MANAGED_BY_ME] = 'Quản lý bởi tôi'
ListingTabType[ListingTabType.NEED_APPROVAL] = 'Cần phê duyệt'
