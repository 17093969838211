import React, { useEffect, useState } from 'react'
import { Paper } from '@mui/material'
import { Box } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { pushQueryState } from '../utils'
import { exportTickets, ticketActions } from 'redux/slices/ticketSlice'
import TicketListingMain from '../components/TicketListingMain'
import ListingTabType from '../constants/tab-type'
import useInitialFilter from 'hooks/useInitialFilter'
import { Image, Text, confirmDialog } from 'components/ui'
import { Table } from 'components/ui/Table'
import * as ticketService from 'services/ticket.service'
import { createMessage } from 'utils/common'
import { MESSAGE_TYPES } from 'constants/common'
import useTicketColumns from 'hooks/useTicketColumns'

const TicketNeedApproval = () => {
  const keyRefetch = useSelector((state) => state.ticket.keyRefetch)
  const userInfo = useSelector((state) => state.user.user)
  const dispatch = useDispatch()
  const [filter, setFilter] = useState({
    page: 1,
  })

  const [initializing, setInitializing] = useState(true)

  const filterQuery = useInitialFilter()

  useEffect(() => {
    filterQuery.then((res) => {
      setFilter({
        ...filter,
        ...res,
        approve_by: userInfo?.id,
      })
      setInitializing(false)
    })
  }, [])

  const onFetchData = async (params) => {
    pushQueryState(params)

    const data = await ticketService.getTickets(params).catch((e) => ({ data: [], pagination: { total: 0 } }))

    return { rows: data.data, total: data.pagination.total }
  }

  const handleDeleteTicket = async (id) => {
    try {
      await ticketService.deleteTicket(id)
      dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'Xóa yêu cầu thành công' }))
      dispatch(ticketActions.changeKeyRefetch(Date.now()))
    } catch (error) {
      dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: 'Xóa yêu cầu không thành công' }))
    }
  }

  const ClickDeleteTicket = async (id) => {
    confirmDialog({
      title: 'Xóa yêu cầu',
      message: 'Bạn có chắc chắn muốn xóa yêu cầu này không ?',
      ok: async () => handleDeleteTicket(id),
      button: { cancel: 'Suy nghĩ lại', ok: 'Đồng ý' },
    })
  }

  const columns = useTicketColumns(userInfo, (id) => ClickDeleteTicket(id))

  return (
    <TicketListingMain
      currentTab={ListingTabType.NEED_APPROVAL}
      initialFilter={{ ...filter }}
      onChangeFilter={(val) => {
        setFilter({
          ...filter,
          ...val,
          page: 1,
        })
      }}
      onExport={(val) => {
        dispatch(
          exportTickets({
            ...filter,
            ...val,
          })
        )
      }}
    >
      <Paper sx={{ width: '100%', mb: 2 }}>
        {!initializing ? (
          <Table
            key={keyRefetch}
            params={filter}
            columns={columns}
            onPageChange={(page) => {
              setFilter({ ...filter, page: page })
            }}
            onPerpageChange={(perPage) => {
              setFilter({ ...filter, per_page: perPage, page: 1 })
            }}
            onFetch={onFetchData}
            emptyDataComponent={
              <Box sx={{ textAlign: 'center', py: 3 }}>
                <Image src="/assets/images/table-empty.png" width={606} height={341} />
                <Text>{'Không tìm thấy yêu cầu. Xin vui lòng thử lại'}</Text>
              </Box>
            }
          />
        ) : null}
      </Paper>
    </TicketListingMain>
  )
}

export default TicketNeedApproval
