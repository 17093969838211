import { transformObjToUrlQuery } from 'utils/common'
import fetchApi from './config'

export const getList = async (data = { name: '', paginate: 1 }) => {
  return await fetchApi(`/stores${transformObjToUrlQuery(data)}`)
}

export const getStore = async (id) => {
  return await fetchApi(`/stores/${id}`)
}

export const createStore = async (data) => {
  return await fetchApi(`/stores`, data, 'post')
}

export const updateStore = async (id, data) => {
  return await fetchApi(`/stores/${id}`, data, 'put')
}

export const deleteStore = async (id) => {
  return await fetchApi(`/stores/${id}`, {}, 'delete')
}
