const { createSlice } = require('@reduxjs/toolkit')

const initialState = {
  user: {},
  isLoading: false,
  isLoggedIn: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserToken: (state) => {
      return {
        ...state,
        isLoading: true,
      }
    },

    getUserTokenSuccess: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        user: action.payload,
      }
    },

    getUserTokenError: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
      }
    },
  },
})
export const { actions: userActions, reducer: userReducer } = userSlice
export const userSlector = (state) => state.user
