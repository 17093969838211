import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import { isString } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

ConfirmBox.propTypes = {
  title: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  showIcon: PropTypes.bool,
}

ConfirmBox.defaultProps = {
  title: 'Xác nhận',
  onOpen: () => {
  },
  onClose: () => {
  },
  onConfirm: () => {
  },
  showIcon: true,
}

function ConfirmBox({ title, onClose, onConfirm, showIcon, onOpen, children }) {
  return (
    <Dialog open={true} onClose={() => onClose()} maxWidth='xs'>
      <DialogTitle sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ flex: 1, textAlign: 'center', fontWeight: 500 }}>{title}</Typography>
        <CloseRoundedIcon sx={{ color: '#999', cursor: 'pointer' }} onClick={() => onClose()} />
      </DialogTitle>
      <DialogContent dividers sx={{ padding: '24px 16px' }}>
        <Stack>
          {isString(children) ? (
            <span
              dangerouslySetInnerHTML={{
                __html: children,
              }}
            ></span>
          ) : (
            children
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2, gap: 1.5 }}>
        <Button
          sx={{
            flex: 1,
            border: `1px solid #eee !important`,
            color: '#333',
            textTransform: 'unset',
            fontSize: '16px',
            lineHeight: '24px',
            padding: '7px 0',
          }}
          variant='outlined'
          onClick={() => onClose()}
        >
          Hủy bỏ
        </Button>
        <Button
          sx={{
            flex: 1,
            textTransform: 'unset',
            fontSize: '16px',
            lineHeight: '24px',
            padding: '7px 0',
          }}
          variant='contained'
          onClick={() => {
            onConfirm()
            onClose()
          }}
        >
          Đồng ý
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmBox
