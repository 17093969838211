import * as yup from 'yup'

yup.setLocale({
  mixed: { required: 'Yêu cầu bắt buộc nhập.' },
  string: {
    required: () => 'Yêu cầu bắt buộc nhập.',
    length: ({ length }) => `validator.length-${length}`,
    min: ({ min }) => `Hãy nhập từ ${min} kí tự trở lên.`,
    max: ({ max }) => `Hãy nhập từ ${max} kí tự trở xuống.`,
    email: () => `Hãy nhập một địa chỉ email hợp lệ.`,
    url: () => `Hãy nhập Url hợp lệ.`,
    number: () => `Hãy nhập Số hợp lệ.`,
  },
  number: {
    min: ({ min }) => `Hãy nhập từ ${min} trở lên.`,
    max: ({ max }) => `Hãy nhập từ ${max} trở lên.`,
  },
})

yup.addMethod(yup.object, 'requireObj', function (mess) {
  mess = mess || 'Yêu cầu bắt buộc nhập.'
  return this.default(undefined).nullable().notOneOf([undefined, null], mess)
})
//#endregion

//#region array
//@ts-ignore
yup.addMethod(yup.array, 'requireArray', function (mess) {
  mess = mess || 'Yêu cầu bắt buộc nhập.'
  return this.default(undefined).nullable().notOneOf([undefined, null], mess).min(1, mess).length(0, mess)
})

export {}
