import { Typography, TypographyProps, styled } from '@mui/material'
import * as React from 'react'
import { Text } from './Text'

/**
 * @typedef {object} ILabelProps
 * @property {boolean} [required]
 */
/**
 * @param {ILabelProps & TypographyProps & React.PropsWithChildren} props
 * @returns
 */

const TextStyled = styled(Text)(({ bold, style }) => {
  if (typeof bold === 'boolean' && bold) {
    return {
      ...style,
      fontWeight: 'bold',
    }
  }

  return {
    ...style,
  }
})

export const Label = (props) => {
  const { required = false, bold = false, children, ...rest } = props

  return (
    <TextStyled mb={1} style={{ fontWeight: bold ? 'bold' : bold }} {...rest} color={'#525252'} component="label">
      {children}
      {required && (
        <Text component={'span'} color={'red'} px={1 / 2}>
          {'*'}
        </Text>
      )}
    </TextStyled>
  )
}
