import { LoadingButton } from '@mui/lab'
import { Button as ButtonMUI, ButtonProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { isBoolean } from 'lodash'
import * as React from 'react'

/**
 * @typedef {object} IButtonProps
 * @property {'primary' | 'secondary'} [typeButton]
 * @property {bolean} isLoading
 */
/**
 * @param {IButtonProps & ButtonProps & React.PropsWithChildren} props
 * @returns
 */
export const Button = (props) => {
  const { typeButton = 'normal', sx, ...rest } = props
  const theme = useTheme()

  const buttonStyle = {
    normal: {
      color: '#333333',
      borderColor: '#EEEEEE',
      backgroundColor: 'white',
    },
    primary: {
      color: 'white',
      borderColor: '#2560E5',
      backgroundColor: '#2560E5',
      '&:hover': { backgroundColor: 'rgba(37, 96, 229, .8)', borderColor: 'rgba(37, 96, 229, .8)' },
    },
    containedDanger: {
      color: 'white',
      borderColor: '#C92127',
      backgroundColor: '#C92127',
      '&:hover': { backgroundColor: 'rgba(201, 33, 39, .8)', borderColor: 'rgba(201, 33, 39, .8)' },
    },
    outlinedInfo: {
      color: '#33333',
      borderColor: '#ffff',
      backgroundColor: '#ffff',
      '&:hover': { color: '#ffff', backgroundColor: 'rgba(201, 33, 39, .8)', borderColor: 'rgba(201, 33, 39, .8)' },
    },
    secondary: {
      color: '#333333',
      borderColor: '#EEEEEE',
      backgroundColor: '#F5F5F5',
    },
    icon: {
      minWidth: 'unset',
      padding: '6px',
      color: '#333333',
      borderColor: '#EEEEEE',
      backgroundColor: '#F5F5F5',
    },
    excel: {
      borderColor: '#ffff',
      backgroundColor: '#ffff',
      '&:hover': { backgroundColor: '#21a366', borderColor: '#21a366', color: '#fff' },
    },
  }

  if (isBoolean(props.isLoading)) {
    return (
      <LoadingButton
        variant="outlined"
        {...rest}
        sx={{ ...buttonStyle[typeButton], ...sx }}
        loading={Boolean(props.isLoading)}
      />
    )
  } else {
    return (
      <ButtonMUI
        variant="outlined"
        {...rest}
        sx={{
          [theme.breakpoints.down('md')]: {
            fontSize: '13px !important',
          },
          ...buttonStyle[typeButton],
          ...sx,
        }}
      />
    )
  }
}
