import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Box, DialogActions, DialogContent, Grid, Stack, Typography } from '@mui/material'
import { FieldRadioGroup } from 'components/form'
import { Button as ButtonCus, Modal, Text, useModal } from 'components/ui'
import { MESSAGE_TYPES } from 'constants/common'
import { Formik } from 'formik'
import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import * as ticketService from 'services/ticket.service'
import { createMessage } from 'utils/common'
import * as Yup from 'yup'
import { formatTicketFilter } from '../utils'

const ModalExportTicket = ({ onExported, filter = {} }) => {
  const dispatch = useDispatch()
  const refForm = useRef()
  const [refModal, { toggle: toggleModal }] = useModal()

  const handleSave = async (values, { setSubmitting }) => {
    setSubmitting(true)

    try {
      const file = await ticketService.getTickets(
        formatTicketFilter({
          ...filter,
          ...values,
          export: true,
        })
      )

      if (file.indexOf('.csv') > -1) {
        window.open(`${file}`, '_blank')
      } else {
        window.open(`https://view.officeapps.live.com/op/view.aspx?src=${file}`, '_blank')
      }

      setSubmitting(false)
      onExported()
      toggleModal(false)
      dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'Xuất file thành công' }))
    } catch (error) {
      setSubmitting(false)
      dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: error.message }))
    }
  }

  return (
    <Stack direction="column" alignItems="center">
      <ButtonCus
        typeButton="excel"
        style={{
          height: '2.5rem',
        }}
        startIcon={
          <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5447_206564)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.35604 0.5H10.0419L14.8579 5.52452V15.286C14.8579 16.508 13.866 17.5 12.644 17.5H4.35604C3.13405 17.5 2.14209 16.508 2.14209 15.286V2.71395C2.14209 1.49196 3.13405 0.5 4.35604 0.5Z"
                fill="#00733B"
              />
              <path
                opacity="0.302"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0347 0.5V5.48139H14.858L10.0347 0.5Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.88712 12.9715H8.1442V13.8269H5.88712V12.9715ZM8.84864 8.60107H11.1129V9.44928H8.84864V8.60107ZM5.88712 8.60107H8.1442V9.44928H5.88712V8.60107ZM8.84864 10.0387H11.1129V10.8941H8.84864V10.0387ZM5.88712 10.0387H8.1442V10.8941H5.88712V10.0387ZM8.84864 11.5338H11.1129V12.3892H8.84864V11.5338ZM5.88712 11.5338H8.1442V12.3892H5.88712V11.5338ZM8.84864 12.9715H11.1129V13.8269H8.84864V12.9715Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_5447_206564">
                <rect width="17" height="17" fill="white" transform="translate(0 0.5)" />
              </clipPath>
            </defs>
          </svg>
        }
        onClick={() => toggleModal(true)}
      >
        {'Xuất excel'}
      </ButtonCus>
      <Modal
        ref={refModal}
        title={
          <Text textAlign={'center'} fontWeight={'bold'} fontSize={16}>
            {'Xuất yêu cầu'}
          </Text>
        }
        maxWidth="sm"
        includeDialogContent={false}
        backdrop="static"
      >
        <Formik
          initialValues={{
            export_data: '',
            output_format: '',
          }}
          validationSchema={() => {
            return Yup.object().shape({
              export_data: Yup.string().required('Vui lòng chọn điều kiện'),
              output_format: Yup.string().required('Vui lòng chọn loại file'),
            })
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleSave(values, { setSubmitting })
          }}
        >
          {(props) => {
            refForm.current = props
            return (
              <form noValidate onSubmit={props.handleSubmit}>
                <DialogContent dividers>
                  <FieldRadioGroup
                    name="export_data"
                    requiredLabel
                    propsContainer={{ component: 'div', style: { width: '100%' } }}
                    options={[
                      { value: 'current_page', label: 'Trang hiện tại' },
                      { value: 'filter_data', label: 'Theo bộ lọc hiện tại' },
                    ]}
                    optionKey={(e) => e.value}
                    optionLabel={(e) => e.label}
                    renderRadio={({ Radio }) => (
                      <Grid container spacing={2} rowSpacing={1 / 6}>
                        <Grid item xs={12} sm={12}>
                          {Radio.Radio1}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          {Radio.Radio2}
                        </Grid>
                      </Grid>
                    )}
                    onChange={() => {
                      props.setFieldValue('store', undefined)
                    }}
                  />

                  <Typography variant="h4" fontWeight="600" sx={{ my: 3 }}>
                    Chọn dạng file sẽ xuất
                  </Typography>

                  <FieldRadioGroup
                    name="output_format"
                    requiredLabel
                    propsContainer={{ component: 'div', style: { width: '100%' } }}
                    options={[
                      { value: 'excel', label: 'Xuất file .xls (Excel)' },
                      { value: 'csv', label: 'Xuất file .csv (Comma Seperated Values)' },
                    ]}
                    optionKey={(e) => e.value}
                    optionLabel={(e) => e.label}
                    renderRadio={({ Radio }) => (
                      <Grid container spacing={2} rowSpacing={1 / 6}>
                        <Grid item xs={12} sm={12}>
                          {Radio.Radio1}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          {Radio.Radio2}
                        </Grid>
                      </Grid>
                    )}
                    onChange={() => {
                      props.setFieldValue('store', undefined)
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Box display={'flex'} alignItems={'center'} width={'100%'}>
                    <Stack direction="row" justifyContent={'flex-end'} spacing={1} style={{ flex: 1 }}>
                      <ButtonCus typeButton="secondary" onClick={() => toggleModal(false)}>
                        {'Hủy bỏ'}
                      </ButtonCus>
                      <ButtonCus type="submit" typeButton="primary" disabled={props.isSubmitting}>
                        {'Xuất dữ liệu'}
                      </ButtonCus>
                    </Stack>
                  </Box>
                </DialogActions>
              </form>
            )
          }}
        </Formik>
      </Modal>
    </Stack>
  )
}
export default ModalExportTicket
