import React, { CSSStyleSheet } from 'react'
import { CardMedia, Skeleton } from '@mui/material'
import { useEffect } from 'react'
import { useState } from 'react'
import styled from '@emotion/styled'

const ImageMUI = styled('img')()

/**
 * @typedef {object} IImageProps
 * @property {string} src
 * @property {string} [alt]
 * @property {number | string} [width]
 * @property {number | string} [height]
 * @property {React.CSSProperties} [style]
 */
/**
 * @param {IImageProps & React.PropsWithChildren} props
 * @returns
 */
export const Image = (props) => {
  const { src, alt, width, height, style } = props

  const [state, setState] = useState('loading')

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    const img = document.createElement('img')
    img.src = src
    img.onload = () => {
      setState('done')
    }
    img.onerror = () => {
      setState('error')
    }
  }

  return state === 'loading' ? (
    <Skeleton variant="rectangular" width={width} height={height} />
  ) : state === 'error' ? (
    <ImageMUI src={'/assets/images/no-thumbnail.jpg'} alt={alt} width={width} height={height} style={style} />
  ) : (
    <ImageMUI src={src} alt={alt} width={width} height={height} style={style} />
  )
}
