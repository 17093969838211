export const Telegram = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 20.0039C15.5228 20.0039 20 15.5268 20 10.0039C20 4.48106 15.5228 0.00390625 10 0.00390625C4.47715 0.00390625 0 4.48106 0 10.0039C0 15.5268 4.47715 20.0039 10 20.0039Z" fill="url(#paint0_linear_4931_154593)"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.52636 9.89788C7.44156 8.62776 9.3855 7.79042 10.3581 7.38589C13.1352 6.23081 13.7123 6.03015 14.0884 6.02351C14.1711 6.02206 14.3561 6.04257 14.4759 6.1398C14.6536 6.28401 14.6546 6.59706 14.6349 6.80425C14.4844 8.3855 13.8332 12.2227 13.5019 13.9937C13.3618 14.7431 13.0857 14.9944 12.8185 15.019C12.2378 15.0724 11.7969 14.6352 11.2344 14.2665C10.3543 13.6896 9.85707 13.3304 9.00273 12.7675C8.01543 12.1168 8.65546 11.7592 9.21812 11.1748C9.36539 11.0219 11.924 8.6946 11.9735 8.48347C11.9798 8.45706 11.9855 8.35862 11.927 8.30667C11.8685 8.25472 11.7823 8.27245 11.72 8.28659C11.6317 8.30663 10.2257 9.23597 7.50195 11.0746C7.10285 11.3486 6.74136 11.4821 6.4175 11.4751C6.06043 11.4675 5.37363 11.2733 4.86304 11.1073C4.23679 10.9037 3.73906 10.7961 3.78242 10.4504C3.805 10.2703 4.05296 10.0861 4.52636 9.89788Z" fill="white"/>
      <defs>
        <linearGradient id="paint0_linear_4931_154593" x1="10" y1="0.00390625" x2="10" y2="19.8477" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2AABEE"/>
          <stop offset="1" stopColor="#229ED9"/>
        </linearGradient>
      </defs>
    </svg>
  )
}