import { Box, Chip, Grid, InputAdornment, Stack, styled, TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import TicketStatusType from 'constants/ticket-status-type'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { formatDate } from 'utils/formater'
import ListingTabType from '../constants/tab-type'
import { ModalFilterAdvanced } from './ListingFilterAdvanced'

const SearchInput = styled(TextField)((props) => {
  return {
    width: 303,
    height: 36,
    borderRadius: 6,
    '& .MuiInputBase-root': {
      height: 36,
      border: 0,
    },
  }
})

const ListingTableToolbar = ({ tab, filter, onChange }) => {
  const theme = useTheme()
  const handleClick = () => {
    console.info('You clicked the Chip.')
  }

  const handleRemoveFilter = (field) => {
    if (field === 'created_at') {
      filter['created_at[gte]'] = null
      filter['created_at[lte]'] = null
    } else if (field === 'deadline') {
      filter['deadline[gte]'] = null
      filter['deadline[lte]'] = null
    } else if (field === 'status') {
      filter['status'] = -1
    } else {
      filter[field] = null
    }

    onChange(filter)
  }

  const handleChange = _.debounce((e) => {
    onChange({ search: e.target.value })
  }, 500)

  const renderListFilter = () => {
    let currentFilter = []

    const arr = [
      // { key: 'status', icon: 'Trạng thái', removeKey: 'status', label: (e) => TicketStatusType[e]['text'] },
      { key: 'created_by', icon: 'Nguời tạo:', removeKey: 'created_by', label: (e) => e?.name },
      { key: 'assignee', icon: 'Nguời xử lý:', removeKey: 'assignee', label: (e) => e?.name },
      { key: 'approve_by', icon: 'Nguời phê duyệt:', removeKey: 'approve_by', label: (e) => e?.name },
      { key: 'area', icon: 'Vùng:', removeKey: 'area', label: (e) => e?.name },
      { key: 'category', icon: 'Danh mục:', removeKey: 'category', label: (e) => e?.name },
      { key: 'tag', icon: 'Nhãn:', removeKey: 'tag', label: (e) => e?.name },
      { key: 'store', icon: 'Cửa hàng:', removeKey: 'store', label: (e) => e?.name },
      {
        key: ['created_at[gte]', 'created_at[lte]'],
        icon: 'Thời gian tạo:',
        removeKey: 'created_at',
        label: (e) => `${formatDate(e[0])} - ${formatDate(e[1])}`,
      },
      {
        key: ['deadline[gte]', 'deadline[lte]'],
        icon: 'Thời gian xử lý:',
        removeKey: 'deadline',
        label: (e) => `${formatDate(e[0])} - ${formatDate(e[1])}`,
      },
    ]
      .map((e) => ({ ...e, key: Array.isArray(e.key) ? e.key : [e.key] }))
      .map((e, index) => {
        const values = e.key.map((e) => filter[e])

        if (values.filter((e) => !!e).length == 0) {
          return undefined
        } else {
          if (window.location.pathname.indexOf('/can-phe-duyet') > -1 && e.removeKey === 'approve_by') {
            return undefined
          }

          if (window.location.pathname.indexOf('/toi-xu-ly') > -1 && e.removeKey === 'assignee') {
            return undefined
          }

          return currentFilter.push(
            <Grid key={index} item>
              <Chip
                icon={<b>{e.icon}</b>}
                label={e.label?.(values.length > 1 ? values : values[0])}
                onClick={handleClick}
                onDelete={() => handleRemoveFilter(e.removeKey)}
                style={{
                  fontSize: '12px',
                  padding: '4px 8px',
                }}
              />
            </Grid>
          )
        }
      })

    return (
      <Stack direction={'row'} useFlexGap flexWrap="wrap" spacing={1 / 2} mt={currentFilter.length > 0 ? 1 : 0}>
        {currentFilter}
      </Stack>
    )
  }

  return (
    <>
      <Stack px={2} py={2} direction="column" style={{ padding: '8px 16px' }}>
        <Grid
          sx={{
            flexDirection: 'column',
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
            },
          }}
          container
        >
          <Grid item xs={12} md={4} xl={4} sx={{ display: 'flex', alignItems: 'center' }}>
            <SearchInput
              placeholder="Nhập tên yêu cầu..."
              fullWidth
              variant="outlined"
              size="small"
              onChange={(e) => handleChange(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_3025_391716)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.833008 7.66585C0.833008 11.4325 3.89967 14.4992 7.66634 14.4992C11.433 14.4992 14.4997 11.4325 14.4997 7.66585C14.4997 3.89919 11.433 0.83252 7.66634 0.83252C3.89967 0.83252 0.833008 3.89919 0.833008 7.66585ZM1.83301 7.66585C1.83301 4.45252 4.44634 1.83252 7.66634 1.83252C10.8863 1.83252 13.4997 4.45252 13.4997 7.66585C13.4997 10.8792 10.8863 13.4992 7.66634 13.4992C4.44634 13.4992 1.83301 10.8792 1.83301 7.66585ZM14.313 15.0195C14.413 15.1195 14.5396 15.1661 14.6663 15.1661C14.793 15.1661 14.9196 15.1195 15.0196 15.0195C15.213 14.8261 15.213 14.5061 15.0196 14.3128L13.6863 12.9795C13.493 12.7861 13.173 12.7861 12.9796 12.9795C12.7863 13.1728 12.7863 13.4928 12.9796 13.6861L14.313 15.0195Z"
                          fill="#CCCCCC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3025_391716">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </InputAdornment>
                ),
              }}
              style={{
                background: '#F5F5F5',
                height: 36,
              }}
            />
            <ModalFilterAdvanced tab={tab} filter={filter} onChange={(val) => onChange({ ...filter, ...val })} />
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            xl={8}
            sx={{ mt: { xs: 1, md: 0 }, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
          >
            <Stack
              direction={'row'}
              useFlexGap
              flexWrap="wrap"
              alignItems="center"
              gap={1}
              sx={{ justifyContent: { xs: 'flex-start', xl: 'flex-end' }, marginTop: { xs: 1.5, md: 0, xl: 0 } }}
            >
              {[
                TicketStatusType.ALL,
                TicketStatusType.OPENING,
                TicketStatusType.PENDING,
                TicketStatusType.PROCESSING,
                TicketStatusType.PROCESSED,
                TicketStatusType.REJECTED,
                TicketStatusType.CLOSED,
                TicketStatusType.DONE,
              ]
                .map((e) => ({ ...TicketStatusType[e], id: e }))
                .map((e, index) => (
                  <Chip
                    key={index}
                    label={e.text}
                    onClick={() => onChange({ ...filter, status: e.id })}
                    sx={{
                      borderRadius: '20px',
                      padding: '8px 15px',
                      [theme.breakpoints.down('md')]: {
                        fontSize: '12px',
                        padding: '4px 16px',
                        height: 'auto',
                      },
                      ...((filter.status == e.id || (e.id === -1 && [null, undefined, ''].includes(filter.status))) && {
                        backgroundColor: '#DB1B33',
                        color: 'white',
                      }),
                      '& .MuiChip-label': {
                        padding: '0 !important',
                      },
                    }}
                  />
                ))}
            </Stack>
          </Grid>
        </Grid>
        {/* <Box>{renderListFilter()}</Box> */}
      </Stack>
    </>
  )
}

ListingTableToolbar.propTypes = {
  onChange: PropTypes.func,
  onExport: PropTypes.func,
  filter: PropTypes.object,
  tab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

ListingTableToolbar.defaultProps = {
  onChange: () => {},
  onExport: () => {},
  filter: {},
  tab: ListingTabType.MY_TICKET,
}

export default ListingTableToolbar
