import { createTheme } from '@mui/material'

export const BASE_THEME = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#2560E5',
      secondary: '#525252',
      dark: '#333',
      grey: '#EEE',
    },
    info: {
      main: '#2560E5',
    },
    success: {
      main: '#CCFBF1',
    },
    secondary: {
      main: '#ff9100',
      contrastText: '#fff',
    },
    button: {
      cancel: '#C92127',
      save: '#2560E5',
      // default: ''
    },
    background: {
      disabled: '#F5F5F5',
    },
  },
  typography: {
    htmlFontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontWeight: 600,
      fontSize: '2.375rem',
      lineHeight: 1.21,
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.875rem',
      lineHeight: 1.27,
    },
    h3: {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '24px',
    },
    h4: {
      fontWeight: 700,
      fontSize: '22px',
      lineHeight: '28px',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    h6: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
    },
    // caption: {
    //   fontWeight: 400,
    //   fontSize: '0.75rem',
    //   lineHeight: 1.66,
    // },
    // body1: {
    //   fontSize: '0.875rem',
    //   lineHeight: 1.57,
    // },
    // body2: {
    //   fontSize: '0.75rem',
    //   lineHeight: 1.66,
    // },
    // subtitle1: {
    //   fontSize: '0.875rem',
    //   fontWeight: 600,
    //   lineHeight: 1.57,
    // },
    // subtitle2: {
    //   fontSize: '0.75rem',
    //   fontWeight: 500,
    //   lineHeight: 1.66,
    // },
    // overline: {
    //   lineHeight: 1.66,
    // },
    button: {
      textTransform: 'capitalize',
      fontWeight: 400,
    },
    fontFamily: 'Be Vietnam Pro, sans-serif',
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          lineHeight: '20px',
          color: '#333333',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#333333',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'unset',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: '10px 12px !important',
          border: '1px solid #EEE',
          fontSize: '14px',

          input: {
            padding: '0 !important',
          },

          '&.Mui-disabled': {
            background: '#f5f5f5 !important',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent !important',
          },

          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent !important',
            },
          },

          '&:focus-visible': {
            border: 'none',
            outline: 'none',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltipPlacementBottom: {
          color: '#333333',
          backgroundColor: 'white',
          boxShadow: '1px 2px 9px #a6a6a6',
        },
        arrow: {
          color: 'white',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#2560E5',
          },
          // color: '#2560E5'
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          // color: '#333333',
          borderRadius: '3px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '16px !important',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#DB1B33',
            color: 'white',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#DB1B33',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#525252',
          '&.Mui-selected': {
            color: '#DB1B33',
            fontSize: 16,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 4px 16px 0px #0000000A',
          border: '0px 0px 1px 0px',
          borderRadius: '6px',
        },
      },
    },
  },
})
