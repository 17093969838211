import CloseIcon from '@mui/icons-material/Close'
import { Stack } from '@mui/material'
import DialogMUI, { DialogProps } from '@mui/material/Dialog'
import DialogActions, { DialogActionsProps } from '@mui/material/DialogActions'
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Button } from './Button'
import { Text } from './Text'

const Dialog = styled(DialogMUI)(({ theme }) => ({
  '& .MuiDialog-container': { alignItems: 'flex-start' },
  '& .MuiPaper-root': { marginTop: '24px' },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

/**
 * @template T
 * @typedef {object} IModalRef
 * @property {(flag: boolean)=> void} toggle
 * @property {(flag: boolean, data: T)=> void} toggleRender
 */
/**
 * @template T
 * @typedef {object} IModalProps<T>
 * @property {React.ReactNode} title
 * @property {boolean | 'static'} backdrop
 * @property {(opts: {toggle?:(flag:boolean)=>void}) => React.ReactNode} titleComponent
 * @property {React.ReactNode} children
 * @property {boolean} [includeDialogContent]
 * @property {(data?:T)=>React.ReactNode} render
 * @property {(opts: {toggle?:(flag:boolean)=>void}) => React.ReactNode} [actionComponent]
 * @property {boolean} [initValue]
 * @property {boolean} [isShow]
 * @property {()=>void} [onHide]
 * @property {()=>void} [onShow]
 * @property {DialogActionsProps} [propsDialogActions]
 * @property {DialogContentProps} [propsDialogContent]
 */
/**
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {()=>void} props.onClose
 * @returns {React.ReactNode}
 */
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props

  return (
    children !== undefined && (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {typeof children === 'string' ? <Text fontWeight={'bold'}>{children}</Text> : children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    )
  )
}

/**
 * @template T
 * @returns {[ref:React.MutableRefObject<IModalRef<T>, opts:IModalRef<T>]}
 */
export const useModal = () => {
  const ref = useRef()
  return [
    ref,
    {
      toggle: (flag, callback) => ref.current?.toggle(flag),
      toggleRender: (flag, data) => ref.current?.toggleRender(flag, data),
    },
  ]
}

/**
 * @template T
 * @param {IModalProps<T> & DialogProps} props
 * @param {React.ForwardedRef<IRefModal<T>>} ref
 * @returns
 */
const _Modal = (props, ref) => {
  const {
    title,
    titleComponent,
    children,
    includeDialogContent = true,
    actionComponent,
    initValue = false,
    render,
    onHide: _onHide,
    onShow: _onShow,
    backdrop = true,
    propsDialogActions,
    propsDialogContent,

    ...rest
  } = props

  const [isOpen, setIsOpen] = useState(initValue)
  const [data, setData] = useState()

  React.useImperativeHandle(ref, () => ({
    toggle,
    toggleRender,
  }))

  const toggle = (flag) => {
    flag = flag == null ? !isOpen : flag
    setIsOpen(flag)
    flag === false ? _onHide?.() : _onShow?.()
  }

  const toggleRender = (flag, _data) => {
    setData({ ...data, ..._data })
    toggle(flag)
    flag === false ? _onHide?.() : _onShow?.()
  }

  const onClose = (event, reason) => {
    if (['backdropClick', 'escapeKeyDown'].includes(reason) && backdrop !== 'static') toggle(false)
  }

  let _children = render !== undefined ? (data === undefined ? render(undefined) : render(data)) : children
  _children = typeof _children == 'string' ? <Typography>{_children}</Typography> : _children

  return (
    <Dialog fullWidth={true} {...rest} onClose={onClose} open={isOpen}>
      {titleComponent !== undefined ? (
        titleComponent({ toggle })
      ) : (
        <BootstrapDialogTitle children={title} onClose={() => toggle(false)} />
      )}
      {includeDialogContent ? (
        <DialogContent dividers {...propsDialogContent}>
          {_children}
        </DialogContent>
      ) : (
        _children
      )}
      {actionComponent !== undefined && (
        <DialogActions {...propsDialogActions}>{actionComponent({ toggle })}</DialogActions>
      )}
    </Dialog>
  )
}

export const Modal = React.forwardRef(_Modal)

/**
 * @template T
 * @param {object} opts
 * @param {React.ReactNode} opts.message
 * @param {IModalProps<T> & DialogProps} opts.props
 */
export const modalFunc = (opts) => {
  const { message = 'Modal ....', props } = opts || {}

  let element = document.createElement('div')
  document.body?.appendChild(element)

  const refModal = React.createRef()

  ReactDOM.render(
    <Modal
      {...props}
      initValue={true}
      ref={refModal}
      onHide={() => {
        props?.onHide?.()
        ReactDOM.unmountComponentAtNode(element)
        element.remove()
      }}
    >
      {message}
    </Modal>,
    element
  )
}

/**
 *
 * @typedef {object} IConfirmProps
 * @property {string} [title]
 * @property {string} [message]
 * @property {()=>void} [ok]
 * @property {()=>void} [cancel]
 * @property {object} [button]
 * @property {string} [button.cancel]
 * @property {string} [button.ok]
 */
/**
 * @param {IConfirmProps | string} _opts
 */
export const confirmDialog = (_opts) => {
  const opts = typeof _opts === 'string' ? { message: _opts } : _opts
  const {
    message = 'Bạn có chắc chắn muốn xóa?',
    title = 'Xác nhận',
    ok,
    cancel,
    button: { cancel: buttonCancel, ok: buttonOK } = { cancel: 'Hủy', ok: 'Xác nhận' },
  } = opts || {}
  modalFunc({
    message: <Text px={2}>{message}</Text>,
    props: {
      title: (
        <Text fontWeight={'bold'} color={'#262626'} textAlign={'center'}>
          {title}
        </Text>
      ),
      backdrop: 'static',
      fullWidth: false,
      actionComponent: ({ toggle }) => (
        <Stack direction={'row'} spacing={2}>
          <Button
            sx={{
              textTransform: 'unset',
            }}
            onClick={() => {
              toggle(false)
              cancel?.()
            }}
          >
            {buttonCancel}
          </Button>
          <Button
            sx={{
              textTransform: 'unset',
            }}
            typeButton="primary"
            onClick={() => {
              toggle(false)
              ok?.()
            }}
          >
            {buttonOK}
          </Button>
        </Stack>
      ),
    },
  })
}
/**
 * @typedef {object} IAlertProps
 * @property {string} [title]
 * @property {string} [message]
 */
/**
 * @param {IAlertProps | string} _opts
 */
export const alertDialog = (_opts) => {
  const opts = typeof _opts === 'string' ? { message: _opts } : _opts
  const {
    message = 'Alert message ....',
    // title = 'Alert',
  } = opts || {}
  modalFunc({
    message: <Typography>{message}</Typography>,
    props: {
      // title,
      fullWidth: true,
      maxWidth: 'sm',
    },
  })
}
