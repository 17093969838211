import { FormHelperText } from '@mui/material';
import * as React from 'react';

/**
 * @typedef {object} ILabelErrorProps
 * @property {string} [error]
 */
/**
 * @param {ILabelErrorProps & React.PropsWithChildren} props 
 * @returns 
 */
export const LabelError = (props) => {
    const { error: _error, children } = props;

    const error = _error || children;
    return !!error && (<FormHelperText style={{ marginLeft: 0 }} error>{error}</FormHelperText>)
}