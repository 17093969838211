import { all, call, put, takeLatest } from 'redux-saga/effects'
import { userActions } from 'redux/slices/userSlice'
import * as userService from '../../services/user.service'

/**
 * Get user info by token
 * @param {*} param0
 */
function* getUserToken({ payload }) {
  try {
    const res = yield call(userService.getUserToken)
    yield put(userActions.getUserTokenSuccess(res))

    if (payload && payload.onSuccess) {
      payload.onSuccess(res)
    }
  } catch (error) {
    yield put(userActions.getUserTokenError(error))
    if (payload && payload.onError) {
      payload.onError(error)
    }
  }
}

export default function* userSaga() {
  yield takeLatest(userActions.getUserToken.type, getUserToken)
}
