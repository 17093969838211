import TicketStatusType from 'constants/ticket-status-type'
import UserRoleLevel from 'constants/user-role-level'
import { isArray, isEmpty, isNull, isPlainObject, isUndefined } from 'lodash'
import { isSubTicket } from 'pages/ticket/utils'

export const isSuperAdmin = (user) => {
  return user?.role?.level === UserRoleLevel.SUPERADMIN
}

export const isRaiser = (ticket, user) => {
  return ticket.raise_ticket_by?.id === user.id
}

const isTicketCoordinator = (ticket, user) => {
  const checkUserInCategory = user.categories.find((item) => item.id === ticket.category?.id)

  return user.role.level === UserRoleLevel.COORDINATOR && !isUndefined(checkUserInCategory)
}

const isRootTicketCoordinator = (ticket, user) => {
  return (
    user.role.level === UserRoleLevel.COORDINATOR &&
    user.categories.find((item) => item.id === ticket.parent?.category?.id) != null
  )
}

const isRootTicketAssignee = (ticket, user) => {
  return ticket.parent?.assignee?.id === user.id
}

const checkIsOwner = (ticket, user) => {
  if (isSubTicket(ticket)) {
    return isRaiser(ticket, user) || isRootTicketCoordinator(ticket, user) || isRootTicketAssignee(ticket, user)
  }

  return isRaiser(ticket, user)
}

export default function checkPermissionTicket(condition, ticket = {}, user = {}) {
  if (isPlainObject(condition)) {
    // Kiểm tra trạng thái
    if (!!condition.status && condition.status !== ticket.status) {
      return false
    }

    // Kiểm tra trạng thái phê duyệt
    if (!!condition?.status_approve && condition?.status_approve !== ticket.status_approve) {
      return false
    }

    // Kiểm tra role
    if (!isUndefined(condition.level) && condition.level !== user?.role?.level) {
      return false
    }

    // Kiểm tra người tạo ticket
    if (!isUndefined(condition.is_owner)) {
      return checkIsOwner(ticket, user) === condition.is_owner
    }

    // Kiểm tra người điều phối ticket
    if (!isUndefined(condition.is_coordinator) && condition.is_coordinator) {
      return isTicketCoordinator(ticket, user)
    }

    // Kiểm tra người người được giao ticket
    if (!isUndefined(condition.is_assignee) && ticket.assignee?.id !== user.id) {
      return false
    }

    // Kiểm tra root ticket

    if (!isUndefined(condition.is_root_ticket) && condition.is_root_ticket) {
      if (isSubTicket(ticket)) {
        return false
      }
    }

    // Yêu cầu chưa có giải pháp <=> chưa được xử lý
    if (!isUndefined(condition.no_solution) && condition.no_solution) {
      if (
        !isUndefined(ticket.solutions) &&
        (!ticket.solutions || !isEmpty(ticket.solutions) || ticket.solutions.length === 0)
      ) {
        return false
      }

      return false
    }

    return true
  } else if (isArray(condition)) {
    let flag = false

    condition.forEach((c, i) => {
      if (checkPermissionTicket(c, ticket, user)) {
        flag = true
      }
    })

    return flag
  }

  return true
}
