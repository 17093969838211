import { transformObjToUrlQuery } from 'utils/common'
import fetchApi from './config'
import { isObject } from 'lodash'

export const updateTicketStatus = async (id, status) => {
  return await fetchApi(`/tickets/${id}/change-status`, { status }, 'post')
}

export const getTickets = (data) => {
  if (['-1', -1].includes(data.status)) delete data.status

  let newData = {}

  Object.keys(data).forEach((key) => {
    if (isObject(data[key])) {
      newData[key] = data[key]?.id
    } else {
      newData[key] = data[key]
    }
  })

  return fetchApi(`/tickets${transformObjToUrlQuery(newData)}`)
}

export const createTicket = async (data) => {
  return fetchApi('/tickets', data, 'post')
}

export const getTicket = async (id) => {
  return fetchApi(`/tickets/${id}`)
}

export const updateTicket = async (id, data) => {
  return fetchApi(`/tickets/${id}`, data, 'put')
}

export const deleteTicket = (id) => {
  return fetchApi(`/tickets/${id}`, {}, 'delete')
}

export const createTicketSolution = async (id, data) => {
  return fetchApi(`/tickets/${id}/solution`, data, 'post')
}

export const updateTicketSolution = async (id, data) => {
  return fetchApi(`/tickets/solutions/${id}`, data, 'put')
}

export const createTicketApprove = async (id, data) => {
  return fetchApi(`/tickets/${id}/approve`, data, 'post')
}

export const updateTicketApprove = async (id, data) => {
  return fetchApi(`/tickets/approve/${id}`, data, 'put')
}

export const getTicketApprovement = async (id, data) => {
  return fetchApi(`/tickets/approve/${id}`)
}

export const closeTicket = async (id, data) => {
  return fetchApi(`/tickets/${id}/close`, data, 'put')
}

export const reopenTicket = async (id) => {
  return fetchApi(`/tickets/${id}/re-open`, {}, 'put')
}

/**
 * Tải tập tin đính kèm
 *
 * @param {File} file
 * @returns Promise
 */
export const uploadAttachmentFile = (file) => {
  let formData = new FormData()
  formData.append('file', file)
  formData.append('title', file.name)
  formData.append('description', file.size)

  return fetchApi('/tickets/attachments/add', formData, 'post', {
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
}

export const createRating = async (data) => {
  return fetchApi(`/tickets/feedback`, data, 'post')
}

export const updateRating = async (id, data) => {
  return fetchApi(`/tickets/feedback/${id}`, data, 'put')
}
