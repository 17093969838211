export default class TicketStatusType {
  static ALL = -1
  static OPENING = 1
  static PENDING = 2
  static PROCESSING = 3
  static PROCESSED = 4
  static REJECTED = 99
  static CLOSED = 9
  static DONE = 10
  static OUTOFDATE = 'outofdate'
}

TicketStatusType[TicketStatusType.ALL] = { text: 'Tất cả', color: '' }
TicketStatusType[TicketStatusType.OPENING] = { text: 'Mở', color: '#4B5563', bgcolor: '#E5E7EB' }
TicketStatusType[TicketStatusType.PENDING] = { text: 'Chờ xử lý', color: '#EA580C', bgcolor: '#FFEDD5' }
TicketStatusType[TicketStatusType.PROCESSING] = { text: 'Đang xử lý', color: '#1D4ED8', bgcolor: '#DBEAFE' }
TicketStatusType[TicketStatusType.PROCESSED] = { text: 'Đã xử lý', color: '#7E22CE', bgcolor: '#F3E8FF' }
TicketStatusType[TicketStatusType.REJECTED] = { text: 'Từ chối', color: '#DB2777', bgcolor: '#FCE7F3' }
TicketStatusType[TicketStatusType.CLOSED] = { text: 'Tạm đóng', color: 'white', bgcolor: '#6B7280' }
TicketStatusType[TicketStatusType.DONE] = { text: 'Hoàn thành', color: '#15803D', bgcolor: '#DCFCE7' }
TicketStatusType[TicketStatusType.OUTOFDATE] = { text: 'Quá hạn', color: '#FF1111' }
